import axios from 'axios';
import Axios from "axios";
import qs from 'qs';
import React, { Component } from 'react';
import Bowser from "bowser";
import moment from "moment";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import debounce from 'lodash/debounce';
import {
  Link
} from 'react-router-dom';
import {
  Radio,
  Select,
  Modal,
  Tooltip,
  Carousel,
  Image,
  Input,
  notification,
  Button,
  Checkbox,
  DatePicker
} from 'antd';

import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import SoapFlow from '../../utils/soap';
import AsideLeft from '../../components/aside/asideleft';
import VitalsKeyFindings from '../patient-detail/sscomponents/vitals-keyfindings';
import ChiefComplaintsHPI from '../patient-detail/sscomponents/chiefcom-hpi';
import ClinicalNotes from '../patient-detail/sscomponents/clinical-notes';
import InternalNotes from '../patient-detail/sscomponents/internal-notes';
import ObjectiveComponentSS from '../patient-detail/objectivess';
import AssessmentComponentSS from './sscomponents/assessmentss';
import GynaeSingleScreen from './sscomponents/gynaesinglescreen';
import ReferralComponentSS from '../patient-detail/referralss';
import PlanTestComponentSS from './planTestSS';
import PlanMedicineComponentSS from './planMedicineSS';
import SupportingMaterialSS from '../../components/supporting-material/supporting-material-form-ss';
import InstructionsForm from '../../components/instructions/instructions-form';
import CurrentMedsPopup from '../../components/drugs/current-meds-popup';
import AdmitOrders from '../../components/admit-orders/admit-orders-form';
import SurgeryRequestForm from '../../components/new-surgery/new-surgery-form';
import SurgeryForm from '../../components/new-surgery/surgery_form';
import TextSaveImg from "../../assets/images/textsave.png";



import {
  AntSelectLabels
} from '../../utils/jQueryLabels';
import {
  headerService$,
  practiceService,
  speechService,
  speechTextService
} from '../../utils/rxjs-sharing';
import {
  ISALLOWEDITSOAP,
  DRUGS_CONSTANT,
  LAB_TEST_CONSTANTS,
  LAB_TEST_SEARCH,
  DEFAULTVITALGROUPID,
  HOSPITALDEFAULTS,
  SEARCH_DIAGNOSIS,
  FETCH_SUPPORTING_MATERIAL,
  PATIENT_DATA,
  REFERENCE_TYPE,
  INSTRUCTION_STORE,
  DURATION_TYPE_OBJECT,
  VITALS_GETALL,
  INTUITIVE_CONSTANTS,
  DURATION_TYPE_OBJECT_REVERSE,
  PHYSIO_UPADTE,
  ADMIS_UPADTE,
  ADMISSION_UPDATE,
  TEMPLATE_FILTER,
  SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS,
  SUBJECTIVE_INFRMATION_CUSTOM,
  SUBJECTIVE_INFRMATION_INTERNAL_NOTES,
  SUBJECTIVE_INFRMATION_CLINICAL_NOTES,
  INTUITIVE_DIAGNOSIS_AND_CP,
  MYHC_APPTYPE,
  WOODLANDS,
  FOLLOWUP_UPDATE,
  TOGGLE_PRACTICE,
  REFERRAL_DETAILS,
  INSTRUCTION_SEARCH,
  SOAP_OBJECTIVE_DETAILS,
  LOADER_RED,
  TAB_NAMES,
  INSTRUCTION_DELETE,
  SUBJECTIVE_CUSTOM_STORE,
  PARENT_CUSTOM_STORE,
  LOADER,
  UPDATE_QMS_STATUS,
  COMPLETE_APPOINTEMENT,
  SEPARATEUPLOADEDDOCSNEW,
  SUBJECTIVE_INFRMATION,
  GET_ARRAY_BUFFER_FORM_URL,
  USERTYPES,
  PARENT_INFRMATION_CUSTOM,
  GET_EXISTING_SURGERIES,
  //PATIENT_CHECKOUT,
  APP_ENV,
  ACTIVE_ADMISSION_REQUEST,
  PATIENT_INSTRUCTION_DELETE,
  SOAP_DETAILS,
  PDF_CONFIG_VITALS_GRAPH,
  CREATE_TEMPLATE,
  GET_DEFAULT_TEXT,
  ALL_CIMS_INTERACTION_EXISTS,
  ALL_CIMS_INTERACTION,
  PRESCRIPTION_DATA,
  HOSPITAL_LISTS,
  PATIENT_REG_DATA,
  ASSESSMENT_API,
  GET_REFERRAL_CONFIGURATION,
  STORE_REFERRED_DETAILS,
  GET_ALL_REFERRED_DETAILS,
  GET_REFERRED_DETAILS_FROM_HIS,
  CASE_TYPE_LIST,
  SEND_TEST_ORDERS,
  COMPLETE_APPOINTEMENT_DRAFT
} from '../../utils/constant';
import AppHelpers from '../../utils/AppHelpers';
import { medicineDataEmptyDisableButton, getMedicineDetails } from '../../utils/rxjs-sharing';
import { doctorNotesService } from "../../utils/rxjs-sharing";
import NotesSaveModel from "../notes-model/NotesSaveModel";
import { async } from 'rxjs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);



const { Option } = Select;
class SoapSingleParent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      reference_id: null,
      details: [],
      field_status: [],
      dataLoading: true,
      physiotherapy_advised: 2,
      physioText: '',
      admissionChecked: false,
      radioChecked: true,
      admissionAdvisedDisabled : false,
      admissionComment: '',
      icu_days: '',
      ward_days: '',
      case_type: null,
      case_type_error: false,
      admission_advice_validation: false,
      followUpRadio: null,
      follow_up_days: null,
      suggestedCP: [],
      otherCP: [],
      sendOrderDisabled: false,
      allCP: [],
      cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
      subjective_custom: {},
      parent_customdata: {},
      cpDiagnosisType: 'Favorites',
      suggestedDiagnosis: [],
      cc_keyword: [],
      internal_notes_keyword: [],
      clinical_notes_keyword: [],
      isAdmissionAdvisedPriority: false,
      follow_up_Others: 'Days',
      follow_up_date: dayjs(),
      actionText :"Add",
      editData: null,
      showInstructionModal: false,
      otherVal: null,
      disableCompleteButton: true,
      bookNowAllow: true,
      referralDetailsArr: [],
      isReferralModal: false,
      addReferralSourceModal: false,
      isReferral: false,
      referralByFromHIS: '',
      referred_by: '',
      isButtonDisabled: true,
      showSupportingModal: false,
      supportingMaterial: [],
      allSupportingMaterial: [],
      isSupportingMaterialShow: true,
      isPracticing: false,
      isPracticingModal: false,
      hide_protocol_by_medi: false,
      hide_protocol_by_test: false,
      hospital_id: null,
      disableConfirmButton: false,
      viewDocumentModal: false,
      allowEdit: true,

      favourite: {},
      intuitive: {},
      constants: {},
      materialList: [],

      active_tab: 'subjective',
      isObjectiveShow: false,
      isMedShow: false,
      isTestShow: false,
      isPrintLoader: false,
      isPrintDraftLoader: false,
      documentURL: '',
      downloadAllfiles: false,
      download_file_loading: false,
      isReferralModal: false,
      showdeletebutton: true,
      speech: {
        identifier: null,
        state: false
      },
      reviewNotes: '',
      reviewTxt: '',
      is_recall_doctor: 0,
      deleteInstructionModal: false,
      medicineDataEmpty: false,
      isEmrConfigAdmissionAdvised: false,
      isEMRButtonDisabled: false,
      admitOrderModal:false,
      surgeryRequestModal: false,
      surgeryModal: false,
      surgeryButtonEnabled: false,
      surgeryEnabled: false,
      surgeryRequestRequired: false,
      surgeryRequired: false,
      viewAdmissionGrid: false,
      admissionListData: [],
      junior_doctor_complete_appointment: false,
      content: '',
      input_type: '',
      model_type: '',
      notesModelActive: false,
      saveAsCareProtocolModal: false,
      savedisabled: true,
      assessmentDataLength: 0,
      drug_id_Interaction_Modalurl: [],
      isModalAllInteractionVisible: false,
      addedDrugsList: [],
      default_text:[],
      gyaneData:
      {
        cycle_length: null,
        lmp: null,
        corrected_edd: null,
        edd_by_lmp:null,
        pregnant: "0",
        is_checked_aph: null,
        test_done: [],
        any_previous_complication: [],
        lactating:false,
        is_confidential:false,
        mother_fetal_printwithprescription:false,
        is_sexual_hx_confidential: '1',
        risk_factors: [],
        current_pregnancy_remarks: "",
        mother_remarks: "",
        sexual_remarks: "",
        gravidity: null,
        parity: null,
        abortion: null,
        live_birth: null,
        ectopic: null,
        gestational_age_lmp_weeks: null,
        gestational_age_lmp_days: null,
        gestational_age_usg_weeks: null,
        gestational_age_usg_days: null,
        remarks: "",
        // edema: "",
        // pallor: "",
        // fundal_hgt: "",
        // cervix_exam: "",
        // preterm_labour: "",
      },
      fetalParamsPrevData: {},
      obsGynaePrevData: {},
      physicalExamPrevData: {},
      // NoOfFetusData: {
      //   no_of_fetus: null
      // },
      multipleFetalParamsData:[],
      caseTypeList: [],

      physicalExamData: {
        edema: "",
        pallor: "",
        fundal_height: "",
        cervix_exam: "",
        preterm_labour: "",
        pog_weeks: null,
        weight: null,
        bpmm: null,
        pulse_rate: null,
      },
       
      childCustomData:{
        custom_field_1: null,
        custom_field_2: null,
        custom_field_3: null,
        custom_field_4: null,
        custom_field_5: null
      },
      parentCustomData:{
        custom_field_parent_1: null,
        custom_field_parent_2: null,
        custom_field_parent_3: null,
        custom_field_parent_4: null,
        custom_field_parent_4: null
      },
      isEditRxTrue: typeof this.props?.location?.state?.patient?.isEditRxTrue != "undefined" ? this.props.location.state.patient.isEditRxTrue : null,
      vitalsDataFromVitals: null,
      templateName: "",
      getDiagnosisList: [],
      sendTestOrder: false,
      showRxModal: false,
      rxPdfPath: null,
      rxPdfPrint: false,
    }
    this.getMaterialData = this.getMaterialData.bind(this);
    this.deleteAllInstruction = this.deleteAllInstruction.bind(this);
    this.handleTestModalPopup = this.handleTestModalPopup.bind(this);
    this.handleDeletePopSubmit = this.handleDeletePopSubmit.bind(this);
    this.instructionCollapseRef = React.createRef();
    this.obsGynaeCollapseRef = React.createRef();
    this.carouselRef = React.createRef();
    this.beforeChange.bind(this);
    this.medicineCollapseRef = React.createRef();
    this.testCollapseRef = React.createRef();
    this.instructionFormRef = React.createRef();
    this.subjectiveChange = debounce(this.subjectiveChange, 800);
    this.instructionCollapseRef = React.createRef();
    this.obsGynaeCollapseRef = React.createRef();
    this.medicineComponentRef = React.createRef();
    this.testComponentRef = React.createRef();
    this.assessmentComponentRef = React.createRef();
    this.vitalAndKfComponentRef = React.createRef();
    this.chiefComponentRef = React.createRef();
    this.viewMedicineData = this.viewMedicineData.bind(this);
    this.addedDrugs = this.addedDrugs.bind(this);
    this.viewTestData = this.viewTestData.bind(this)
    this.printCompleteButtonDiabled = this.printCompleteButtonDiabled.bind(this);
    this.saveCustomSubjectiveFromDoctor = this.saveCustomSubjectiveFromDoctor.bind(this);
    this.saveTextBoxData = this.saveTextBoxData.bind(this);
    this.resetNotesModel = this.resetNotesModel.bind(this);
    this.clinicalNotesRef = React.createRef();
    this.internalNotesRef = React.createRef();
    this.saveCustomSubjectiveToServer = debounce(this.saveCustomSubjectiveToServer, 1000);
    this.saveParentCustomSubjectiveToServer = debounce(this.saveParentCustomSubjectiveToServer, 1000);
  }

  handleAdmitOrderModal = async () => {
    this.setState({
      admitOrderModal: false,
    })
  }

  handleCaseType = async (e) => {
    const { name, value } = e.target;
    var case_type_data = this.state.caseTypeList.filter(o => o.id == value);
    let case_type_code = 'MD';
    if(case_type_data && case_type_data.length > 0)
    {
      case_type_code = case_type_data[0].code;
    }
    if (case_type_code == 'SG') {
      if(this.state.admission_advice_validation == 'true')
      {
        this.handleSurgery();
        await this.getExisitingSurgeries();
        this.setState({
          surgeryEnabled: true,
        });
      }
      this.setState({
        surgeryButtonEnabled: true,
      });
      await this.getExisitingSurgeriesRequest();
      
    }
    else {
      this.setState({
        surgeryButtonEnabled: false,
        surgeryEnabled: false,
        surgeryRequestRequired: false,
        surgeryRequired: false,
      })
    }
    await this.setState({
      [name]: value
    }, this.admissionSave);
  }

  async getExisitingSurgeriesRequest() {
    const { patient } = this.state;
    const { qms_token_id } = patient;
    const surgery_list_data = await axios.get(GET_EXISTING_SURGERIES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id, surgery_request_type : 1 }));
    if (typeof surgery_list_data.data != 'undefined' && surgery_list_data.data.status == false) {
      await this.setState({
        surgeryRequestRequired: true,
      });
    }
  }

  async getExisitingSurgeries() {
    const { patient } = this.state;
    const { qms_token_id } = patient;
    const surgery_list_data = await axios.get(GET_EXISTING_SURGERIES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id, surgery_request_type : 2 }));
    if (typeof surgery_list_data.data != 'undefined' && surgery_list_data.data.status == false) {
      await this.setState({
        surgeryRequired: true,
      });
    }
  }

  setCycleLength=(data)=>{
    this.setState({
      gyaneData:data
    })
  }

  setPhysicalExamData = (data) => {
    this.setState({physicalExamData: {...data}})
  }

  setMultipleFetalParams = (data) => {
    const {multipleFetalParamsData} = this.state;
    this.setState({
      multipleFetalParamsData: data
      
    })
  }

  addedDrugs = (data) => {
    this.setState({
      addedDrugsList: data
    })
  }

  handleAllInteractionCancel = () => { this.setState({ isModalAllInteractionVisible: false }); };

  handleAllInteractionOk = () => { this.setState({ isModalAllInteractionVisible: false }) };



  showModalInteraction = () => {
    let { addedDrugsList } = this.state;
    var drug_array = [];
    if (addedDrugsList && addedDrugsList.length > 0) {
      drug_array = addedDrugsList;
    }
    let medicineScreen = 'medicineform';
    let referenceId = LS_SERVICE.get("call_patient_qms_token_id");
    let StoreId = LS_SERVICE.get("staff_id") ? LS_SERVICE.get("staff_id") : 0;
    let FacilityId = LS_SERVICE.get("FACILITY_ID") ? LS_SERVICE.get("FACILITY_ID") : 0;
    let enterpriseId = LS_SERVICE.get("ENTERPRISE_ID") ? LS_SERVICE.get("ENTERPRISE_ID") : 0;
    let patientId = LS_SERVICE.get("call_patient_id") ? LS_SERVICE.get("call_patient_id") : 0;
    let referenceType = 0;
    let orederSetId = 0;
    let careProtoColId = 0;
    let showAllInteractionModal = false;
    let addedAssessmentList = 0;
    if (this.state.cimsallow) {
      var drugIds = drug_array.join('-');
      axios.get(ALL_CIMS_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList }))
        .then(success => {
          if (success.data.interaction) {
            showAllInteractionModal = true;
            this.setState({ drug_id_Interaction_Modalurl: drugIds, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId, addedAssessmentList: addedAssessmentList, medicineScreen: medicineScreen, isModalAllInteractionVisible: showAllInteractionModal });
          } else {
            notification.error({
              message: `No interactions Found`,
              placement: 'topRight'
            })
          }
        })
        .catch(err => console.log(err))
    } else {
      this.setState({ isModalAllInteractionVisible: false })
    }
  };

  handleTabClick = (tab) => {
    if (tab == 'objective') {
      this.setState({
        isObjectiveShow: true
      });
    }
    else if (tab == "plan_test") {
      this.setState({
        isTestShow: true
      });
    }
    else if (tab == "plan_medicine") {
      this.setState({
        isMedShow: true
      });
    }
  }

  handleAdmitOrder = event => {
    this.setState({
      admitOrderModal: true,
    });
  }

  handleSurgeryRequest = async (event) => {
    try {
      await this.loadDiagnosisData(); 
      if (this.state.getDiagnosisList?.length === 0) {
        this.setState({
          surgeryRequestModal: false,
        });
        notification.error({
          message: "Please add diagnosis to place a surgery request",
          placement: 'topRight'
        });
      } else {
        this.setState({
          surgeryRequestModal: true,
        });
      }
    } catch (error) {
      console.error("Error handling surgery request:", error);
    }
  }
  
  handleSurgery = event => {
    this.setState({
      surgeryModal: true,
    });
  }

  handleSurgeryFormCancel = () => {
    this.setState({
      surgeryModal: false,
      surgeryRequired: false
    });
  }

  handleSurgeryCancel = () => {
    this.setState({
      surgeryRequestModal: false,
      surgeryRequestRequired: false
    });
  }

  handleOnTextChange = event => {
    const { name, value } = event.currentTarget;
    this.setState({
      [name]: value
    }, this.admissionSave);
  }

  loadCareProtocolAddedData = async () => {
    await this.setState({
      isTestShow: false,
      isMedShow: false,
      isSupportingMaterialShow: false
    });
    this.setState({
      isTestShow: true,
      isMedShow: true,
      isSupportingMaterialShow: true
    });
    this.loadInstructionData();
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
    this.subjectiveChange();
    this.getCustomSubjectiveData();
    this.getCustomParentData();
  }

  loadCopyPrescriptionAddedData = async () => {
    await this.setState({
      isTestShow: false,
      isMedShow: false,
      isSupportingMaterialShow: false
    });
    this.setState({
      isTestShow: true,
      isMedShow: true,
      isSupportingMaterialShow: true
    });
    this.loadInstructionData();
    this.assessmentComponentRef.current.handleAssessmentData();
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
    this.subjectiveChange();
    this.getCustomSubjectiveData();
    this.getCustomParentData();
  }

  autoHeight = (e) => {
    if (e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  }

  viewlengthMedicineData(data) {
    this.setState({ medicineLength: data })
    if (data == 0) {
      this.setState({
        hide_protocol_by_medi: false,
        addedDrugsList: []
      })
    } else {
      this.setState({
        hide_protocol_by_medi: true
      })
    }
  }

  viewlengthTestData(data) {
    this.setState({ testLength: data })
    if (data == 0) {
      this.setState({
        hide_protocol_by_test: false
      })
    } else {
      this.setState({
        hide_protocol_by_test: true
      })
    }
  }
  
  viewlengthUnsentTestData(data) {
    this.setState({ unsentTestLength: data })
  }

  viewMedicineData() {
    if (this.medicineCollapseRef.current?.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
  }

  viewTestData() {
    if (this.testCollapseRef.current?.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
  }
  // componentWillMount() {
  //   this.subscribtion?.unsubscribe();
  // }

  async componentDidMount() {
    // this.subscribtion = medicineDataEmptyDisableButton.status().subscribe(data => {
    //   this.setState({disableCompleteButton:!data.medicineDataEmpty})
    // })
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    this.getCaseTypeList();
    this.setState({
      isMedShow: true,
      isTestShow: true,
    })
    
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    const ss_configuration = LS_SERVICE.get('ss_configuration');
    const patient_full_data = LS_SERVICE.get('call_patient_detail');
    const consult_type = patient_full_data?.consult_type == 1 ? 'vc' : 'opd';

    const isObsGynaePatient = LS_SERVICE.get('isObsGynaePatient') ? LS_SERVICE.get('isObsGynaePatient') : false;

    if(ss_configuration.some(o => (o?.id === 16 && o.sub_items.some(p => ((p?.id === 21 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 22 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 23 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 24 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 25 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 27 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 28 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 29 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 30 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 31 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 32 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 33 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) && ((isObsGynaePatient || isObsGynaePatient == 'true')) && (patient_full_data.age > 10)){
      const objectiveGetAllPromise = await Axios.get(VITALS_GETALL({ patient_id }));
      if (objectiveGetAllPromise.data.status) {
        const { data } = objectiveGetAllPromise.data;
        const { details, constants } = data;
        if(details?.visitVitals?.[0]){
          await this.setState({
            vitalsDataFromVitals: {
              V002: details?.visitVitals?.[0]?.V002,
              V009: details?.visitVitals?.[0]?.V009,
              V005: details?.visitVitals?.[0]?.V005,
              V006: details?.visitVitals?.[0]?.V006
            }
          });
        }
      }
    }

    this.setState({
      ss_configuration: ss_configuration,
      consult_type: consult_type
    })
    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true,
      })
    }

    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }

    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

    if(LS_SERVICE.has('search_patient_page') && LS_SERVICE.get('search_patient_page'))
    {
      allowEdit = false
    }

    this.loadData();
    this.setState({
      hospital_id,
      patient_id,
      reference_id,
      patient,
      pres_modification: hospitalConfigData?.pres_modification,
      allow_hours: hospitalConfigData?.allow_hours,
      allowEdit
    }, async () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.fetchHospitalDetails();
        await this.getPatientData();
        this.getReferralDetailsFromHis();
        this.handleSubjectivesData();
        SoapFlow.hospital(+hospital_id);
        this.loadInstructionData();
      }
    });
    this.checkJuniorDoctorCompleteAppoitment();
    await this.getCustomSubjectiveData();
    await this.getCustomParentData();
    this.getReferralConfiguration();
    this.getAllReferrealData();
    let list = LS_SERVICE.get('call_patient_detail').list;
    if (list == 'worklist' || list == 'pending') {
      this.handelDefaultText();
    }
    this.subscription = speechTextService.status().subscribe(async data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'custom_field_1') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom1: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_1', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_2') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom2: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_2', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_3') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom3: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_3', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_4') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom4: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_4', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_5') {
          await this.setState((prevState) => ({ subjective_custom: { ...prevState.subjective_custom, custom5: data.text } }));
          this.saveCustomSubjective({ target: { name: 'custom_field_5', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_1') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom1: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_1', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_2') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom2: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_2', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_3') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom3: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_3', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_4') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom4: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_4', value: data.text } });
        }
        if (this.state.speech.identifier === 'custom_field_parent_5') {
          await this.setState((prevState) => ({ parent_customdata: { ...prevState.parent_customdata, parent_custom5: data.text } }));
          this.saveParentCustomSubjective({ target: { name: 'custom_field_parent_5', value: data.text } });
        }
        if (this.state.speech.identifier === 'review_notes') {
          await this.setState({ reviewTxt: data.text });
        }
        if (this.state.speech.identifier === 'clinical_notes') {
          this.setState({ clinical_notes: data.text });
        }
        if (this.state.speech.identifier === 'internal_notes') {
          this.setState({ internal_notes: data.text });
        }
        if (this.state.speech.identifier === 'chief_complaint') {
          this.setState({ chief_complaint: data.text });
        }
        if (this.state.speech.identifier === 'review_of_systems') {
          this.setState({ review_of_systems: data.text });
        }
        if(this.state.speech.identifier){
        document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
        }
      }
    });
    this.getReferralDetails();
    let Elem = document.querySelectorAll('.autoHeightText');
    Elem.forEach(function (el) { el.style.height = el.scrollHeight + "px"; });
  }

  async componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  checkAdmissionCaseTypeFlag = async () => {
    const hospitalId = parseInt(LS_SERVICE.get("slot_hospital_id"));
    const response = await axios.post(HOSPITAL_LISTS, {
      hospitalId: hospitalId
    });
    let data = response.data.data;
    this.setState({
      admission_advice_validation: data?.admission_advice_validation_enabled,
    });
  }
  

  checkJuniorDoctorCompleteAppoitment() {
    let junior_doctor_complete_appointment = LS_SERVICE.get('junior_doctor_complete_appointment');
    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    let call_patient_detail = LS_SERVICE.get('call_patient_detail');
    let current_login_doctor = LS_SERVICE.get('staff_id');
    if (is_junior_doctor == true && current_login_doctor != call_patient_detail.doctor_id) {
      this.setState({
        junior_doctor_complete_appointment: !junior_doctor_complete_appointment
      })
    }
  }

  deleteAllInstruction() {
    this.setState({
      deleteInstructionModal: true
    })
  }

  handleTestModalPopup() {
    this.setState({
      deleteInstructionModal: false
    })
  }

  handleDeletePopSubmit() {
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.reference_id,
      instruction_id: 0
    }
    axios.get(PATIENT_INSTRUCTION_DELETE(PARAMS))
      .then(success => {
        this.setState({
          instructionList: null,
          deleteInstructionModal: false
        })
      });
  }

  onInstructionSelect = async (id, newInstruction, oldInstruction) => {
    let { instructionList } = this.state;

    var instructionExist = -1;
    instructionList.map((v, k) => {
      if (id != -1 && v.id == id) {
        instructionExist = k;
      }
      else if (v.instruction == newInstruction) {
        instructionExist = k;
      }
    })
    if (instructionExist != -1) {
      let intruct_is = await this.handleInstructionDelete(id);
      instructionList.splice(instructionExist, 1);
    }
    else {
      instructionList.push({ id: id, instruction: newInstruction });
    }
    this.setState({ instructionList })
  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'custom_field_1') {
      initialText = this.state?.subjective_custom?.custom1 != undefined ? this.state?.subjective_custom?.custom1 : '';
    }
    if (info.identifier === 'custom_field_2') {
      initialText = this.state?.subjective_custom?.custom2 != undefined ? this.state?.subjective_custom?.custom2 : '';
    }
    if (info.identifier === 'custom_field_3') {
      initialText = this.state?.subjective_custom?.custom3 != undefined ? this.state?.subjective_custom?.custom3 : '';
    }
    if (info.identifier === 'custom_field_4') {
      initialText = this.state?.subjective_custom?.custom4 != undefined ? this.state?.subjective_custom?.custom4 : '';
    }
    if (info.identifier === 'custom_field_5') {
      initialText = this.state?.subjective_custom?.custom5 != undefined ? this.state?.subjective_custom?.custom5 : '';
    }
    if (info.identifier === 'custom_field_parent_1') {
      initialText = this.state?.parent_customdata?.parent_custom1 != undefined ? this.state?.parent_customdata?.parent_custom1 : '';
    }
    if (info.identifier === 'custom_field_parent_2') {
      initialText = this.state?.parent_customdata?.parent_custom2 != undefined ? this.state?.parent_customdata?.parent_custom2 : '';
    }
    if (info.identifier === 'custom_field_parent_3') {
      initialText = this.state?.parent_customdata?.parent_custom3 != undefined ? this.state?.parent_customdata?.parent_custom3 : '';
    }
    if (info.identifier === 'custom_field_parent_4') {
      initialText = this.state?.parent_customdata?.parent_custom4 != undefined ? this.state?.parent_customdata?.parent_custom4 : '';
    }
    if (info.identifier === 'custom_field_parent_5') {
      initialText = this.state?.parent_customdata?.parent_custom5 != undefined ? this.state?.parent_customdata?.parent_custom5 : '';
    }
    if (info.identifier === 'review_notes') {
      initialText = this.state?.reviewTxt != undefined ? this.state?.reviewTxt : '';
    }
    if (info.identifier === 'clinical_notes') {
      initialText = info.currentValue;
    }
    if (info.identifier === 'internal_notes') {
      initialText = info.currentValue;
    }
    if (info.identifier === 'chief_complaint') {
      initialText = info.currentValue;
    }
    if (info.identifier === 'review_of_systems') {
      initialText = info.currentValue;
    }


    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {

        // console.log("In IF info.identifier===state.speech calling with : ", this.state.speech)
        speechService.init(this.state.speech);

        /* if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        } */

      });
    } else {
      // console.log("IN Different MIC Previous Unclosed")
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          /* this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          })) */
          this.handleRestartMic(info, initialText)
        }
        // console.log("State in init:",this.state.speech)

      });

    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      /* if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      } */

      speechService.init(this.state.speech);
    });

  }

  handleOnFocus = (e) => {
    var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier;
    this.setState(prevState => ({
      speech: {
        ...prevState.speech,
        identifier: identifierValue,
        state: false,
        initialText: identifierValue === "custom_field_1" ? this.state?.subjective_custom?.custom1 : identifierValue === "custom_field_2" ? this.state?.subjective_custom?.custom2 : identifierValue === "custom_field_3" ? this.state?.subjective_custom?.custom3 : identifierValue === "custom_field_4" ? this.state?.subjective_custom?.custom4 : identifierValue === "custom_field_5" ? this.state?.subjective_custom?.custom5 :
          identifierValue === "custom_field_parent_1" ? this.state?.parent_customdata?.parent_custom1 : identifierValue === "custom_field_parent_2" ? this.state?.parent_customdata?.parent_custom2 : identifierValue === "custom_field_parent_3" ? this.state?.parent_customdata?.parent_custom3 : identifierValue === "custom_field_parent_4" ? this.state?.parent_customdata?.parent_custom4 : identifierValue === "custom_field_parent_5" ? this.state?.parent_customdata?.parent_custom5 : identifierValue ? 'review_notes' : ''
      },
      /* checkForEditsTrainingData: {
        ...prevState.checkForEditsTrainingData,
        [e.target.id]: false
      } */
    }), () => {
      speechService.init(this.state.speech)
      // this.initSpeech({identifier: e.target.id})
    })

  }

  saveCustomSubjective = async e => {
    const { subjective_custom } = this.state;
    let { childCustomData } = this.state;
    let { name, value } = e.target;
    value = value.charAt(0).toUpperCase() + value.slice(1);
    childCustomData[`${name}`] = value;
    this.setState({childCustomData})
    if (subjective_custom && subjective_custom != null) {
      if (name == 'custom_field_1' && value != undefined && value != null) { subjective_custom.custom1 = value; }
      if (name == 'custom_field_2' && value != undefined && value != null) { subjective_custom.custom2 = value; }
      if (name == 'custom_field_3' && value != undefined && value != null) { subjective_custom.custom3 = value; }
      if (name == 'custom_field_4' && value != undefined && value != null) { subjective_custom.custom4 = value; }
      if (name == 'custom_field_5' && value != undefined && value != null) { subjective_custom.custom5 = value; }
      await this.setState({
        subjective_custom: subjective_custom
      });
      this.saveCustomSubjectiveToServer(name, value);
    }
  }

  saveCustomSubjectiveToServer = async(name, value) => {
    const { patient } = this.state;
    var PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      [name]: value
    }
    axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS))
  }

  saveParentCustomSubjective = async e => {
    const { parent_customdata } = this.state;
    let {parentCustomData} = this.state;
    let { name, value } = e.target;
    value = value.charAt(0).toUpperCase() + value.slice(1);
    parentCustomData[`${name}`] = value;
    this.setState({parentCustomData})
    if (parent_customdata && parent_customdata != null) {
      if (name == 'custom_field_parent_1' && value != undefined && value != null) { parent_customdata.parent_custom1 = value; }
      if (name == 'custom_field_parent_2' && value != undefined && value != null) { parent_customdata.parent_custom2 = value; }
      if (name == 'custom_field_parent_3' && value != undefined && value != null) { parent_customdata.parent_custom3 = value; }
      if (name == 'custom_field_parent_4' && value != undefined && value != null) { parent_customdata.parent_custom4 = value; }
      if (name == 'custom_field_parent_5' && value != undefined && value != null) { parent_customdata.parent_custom5 = value; }
      await this.setState({
        parent_customdata: parent_customdata
      });
      this.saveParentCustomSubjectiveToServer(name, value);
    }
  }

  saveParentCustomSubjectiveToServer = async(name, value) => {
    const { patient } = this.state;
    var PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      [name]: value
    }
    axios.post(PARENT_CUSTOM_STORE, qs.stringify(PARAMS))
  }

  saveCustomSubjectiveOnPrint = async () => {
    let { childCustomData, patient, subjective_custom } = this.state;
      var PARAMS = {
        reference_id: patient.qms_token_id,
        reference_type: LS_SERVICE.get('reference_type'),
        patient_id: patient.patient_id,
        doctor_id: LS_SERVICE.get('staff_id'),
        isPrintAndComplete: true
      }
      if(subjective_custom["custom1"]!=null){
        PARAMS.custom_field_1 = subjective_custom["custom1"]
      }
      if(subjective_custom["custom2"]!=null){
        PARAMS.custom_field_2 = subjective_custom["custom2"]
      }
      if(subjective_custom["custom3"]!=null){
        PARAMS.custom_field_3 = subjective_custom["custom3"]
      }
      if(subjective_custom["custom4"]!=null){
        PARAMS.custom_field_4 = subjective_custom["custom4"]
      }
      if(subjective_custom["custom5"]!=null){
        PARAMS.custom_field_5 = subjective_custom["custom5"]
      }
      
      await axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS));
  }

  saveCustomParentSubjectiveOnPrint = async () => {
    // await this.getCustomParentData();
    let { parentCustomData, patient, parent_customdata } = this.state;
      var PARAMS = {
        reference_id: patient.qms_token_id,
        reference_type: LS_SERVICE.get('reference_type'),
        patient_id: patient.patient_id,
        doctor_id: LS_SERVICE.get('staff_id'),
        isPrintAndComplete: true,
      }
      if(parent_customdata["parent_custom1"]!=null){
        PARAMS.custom_field_parent_1 = parent_customdata["parent_custom1"]
      }
      if(parent_customdata["parent_custom2"]!=null){
        PARAMS.custom_field_parent_2 = parent_customdata["parent_custom2"]
      }
      if(parent_customdata["parent_custom3"]!=null){
        PARAMS.custom_field_parent_3 = parent_customdata["parent_custom3"]
      }
      if(parent_customdata["parent_custom4"]!=null){
        PARAMS.custom_field_parent_4 = parent_customdata["parent_custom4"]
      }
      if(parent_customdata["parent_custom5"]!=null){
        PARAMS.custom_field_parent_5 = parent_customdata["parent_custom5"]
      }
      await axios.post(PARENT_CUSTOM_STORE, qs.stringify(PARAMS))
  }
  
  admissionListLoad () {
    if ((!this.state.admissionListData || this.state.admissionListData.length === 0)) {
      let htmlData = (
        <tr className="view_details">
          <td className="inner_table text-center" colSpan={7}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }

    return this.state.admissionListData.map((result, i) => {
      let htmlData = (
        <tr>
          <td>{result.admissionRequestNo}</td>
          <td>{result.admissionRequestby}</td>
          <td>{moment(result.enteredDate).format('YYYY-MM-DD')}</td>
          <td>{result.caseTypeName}</td>
          <td>{result.admittingPractitionerName}</td>
          <td>{result.admittingSpecialityName}</td>
          <td>{result.remarks}</td>
        </tr>
      )
      return htmlData;
    });
  } catch(err) {
    console.log('admissionListData: ', err);
  }

  getCustomSubjectiveData = () => {
    const { match: { params } } = this.props;
    const { reference_id } = params;

    if (reference_id) {
      axios.get(SUBJECTIVE_INFRMATION_CUSTOM({ reference_type: LS_SERVICE.get('reference_type'), reference_id: reference_id }))
        .then(res => {
          this.setState({
            subjective_custom: res.data.custom_data || {}
          }
          )
        })
    }
  }
  getCustomParentData = () => {
    const { match: { params } } = this.props;
    const { reference_id } = params;

    if (reference_id) {
      axios.get(PARENT_INFRMATION_CUSTOM({ reference_type: LS_SERVICE.get('reference_type'), reference_id: reference_id }))
        .then(res => {
          this.setState({
            parent_customdata: res.data.custom_data || {}
          }
          )
        })
    }
  }

  handleSubjectivesData = async () => {
    const { patient } = this.state;
    const subjectivePromise = await axios.get(SUBJECTIVE_INFRMATION({ reference_type: LS_SERVICE.get('reference_type'), reference_id: patient?.qms_token_id }))

    if (subjectivePromise.data.status) {
      let { appointment } = subjectivePromise.data;

      const { patient_note_doctor, patient_docments } = appointment;

      const docs = SEPARATEUPLOADEDDOCSNEW(patient_docments);
      this.setState({
        patient_note_doctor: patient_note_doctor || '',
        patient_docments: docs,
      });
    }
  }

  getReferralDetails = () => {
    const { match: { params } } = this.props;
    const { patient_id, reference_id } = params;

    // const doctor_id = LS_SERVICE.get('staff_id');
    const doctor_id = LS_SERVICE.get('call_patient_detail').doctor_id;


    const PARAMS = {
      patient_id: patient_id,
      // speciality_id: LS_SERVICE.get('speciality_id') || null,
      speciality_id: LS_SERVICE.get('call_patient_detail').speciality_id || null,
      reference_id: reference_id,
      doctor_id: doctor_id,
    }

    axios.post(REFERRAL_DETAILS, qs.stringify(PARAMS))
      .then(res => {
        if (res.data.status && res.data.data.length > 0) {
          this.setState({
            referralDetailsArr: res.data.data
          })
        }
      })
  }

  getPatientData = async _ => {
    const { match: { params } } = this.props;
    const { reference_id } = params;
    var reference_type = await LS_SERVICE.get('reference_type');
    await axios.get(PATIENT_DATA({ reference_id, reference_type }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
        }
        let encounter_id = success.data.data.results?.qmsToken?.Booking?.encounter_id || 0;
        LS_SERVICE.set('PATIENT_ENCOUNTER_ID',encounter_id);
        const reviewTxt = success.data.data.results.visit.review_notes
        const is_recall_doctor = success.data.data.results?.qmsToken?.is_recall_doctor
        this.setState({
          patient,
          reviewTxt,
          is_recall_doctor
        }, () => {
          this.initialSetup();
          this.getPatientRegData();
        })
      })
      .catch(err => console.log(err))
  }

  getPatientRegData = async () => {
    const { patient } = this.state;
    const PARAMS = {
      RegistrationNo: patient.uhid,
      enterpriseId: parseInt(LS_SERVICE.get('ENTERPRISE_ID')),
      timeZoneMinutes: 330,
    }

    axios.post(PATIENT_REG_DATA, qs.stringify(PARAMS))
    .then(async success => {
      await LS_SERVICE.set('PATIENT_REG_DATA',success.data.data)
    })
    .catch(err => console.log(err))
  }

  initialSetup = () => {
    const { patient, hospital_id } = this.state;
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type') || 0,
      patient_id: patient.patient_id,
      reference_id: patient.qms_token_id,
      hospital_id
    }
    axios.post(SOAP_OBJECTIVE_DETAILS, qs.stringify(PARAMS))
      .then(async res => {

        const { details, field_status } = res.data;
        let { followUpRadio, follow_up_days, follow_up_Others, otherVal } = this.state;

        let followUpType = null;
        let followUpDays = null;
        if (details.followUp !== null) {
          console.log("details.followUp--->", details.followUp);
          followUpType = details.followUp?.followUpType;
          followUpDays = details.followUp?.followUpDays;

          if (followUpType !== 0 && followUpType !== undefined) {
            followUpRadio = -1;
            otherVal = followUpDays;
            follow_up_Others = (DURATION_TYPE_OBJECT_REVERSE[followUpType]?? "Days").toLowerCase();
          } else if (followUpDays !== undefined) {
            follow_up_days = followUpDays;
            followUpRadio = followUpDays
          }
          
        }

        const bookNowAllowFn = _ => {
          if (otherVal !== null || follow_up_days !== null) {
            if (otherVal > 0) return false;
            if (follow_up_days > 0) return false;
          }
          return true;
        }

        if (details.case_type != null) {
          var case_type_data = this.state.caseTypeList.filter(o => o.id == details.case_type);
          let case_type_code = 'MD';
          if(case_type_data && case_type_data.length > 0)
          {
            case_type_code = case_type_data[0].code;
          }
          if(case_type_code == "SG")
          {
            if(this.state.admission_advice_validation == 'true')
            {
              this.setState({
                surgeryEnabled: true,
              });
              await this.getExisitingSurgeries();
            }
            this.setState({
              surgeryButtonEnabled: true,
            });
            await this.getExisitingSurgeriesRequest();
          }
        }

        if(details.admissionAdvised != null)
        {
          this.setState({
            isEmrConfigAdmissionAdvised: false,
          });
        }
        if(details.obsGyaneData != null && details.obsGyaneData.length > 0){
          if(details.fetalParamsData != null && details.fetalParamsData.length > 0){
            details.obsGyaneData[0].mother_remarks = details.fetalParamsData[0]?.remarks
          }
          if(details.sexualRemarksData != null && details.sexualRemarksData.length > 0){
            details.obsGyaneData[0].sexual_remarks = details.sexualRemarksData[0]?.remarks
          }
          if(details.confidentialData != null && details.confidentialData.length > 0){
            details.obsGyaneData[0].is_sexual_hx_confidential = details.confidentialData[0]?.type == 2 && details.confidentialData[0]?.isConfidential == '0' ? '0' : '1'
          }
          else{
            details.obsGyaneData[0].is_sexual_hx_confidential = '0'
          }
          this.setState({
            gyaneData: details.obsGyaneData[0]
          })
        }
        if(details.fetalParamsPrevData != null && details.fetalParamsPrevData.length > 0){
          this.setState({
            fetalParamsPrevData: details.fetalParamsPrevData[0]
          })
        }
        if(details.obsGynaePrevData != null && details.obsGynaePrevData.length > 0){
          this.setState({
            obsGynaePrevData: details.obsGynaePrevData
          })
        }
        if(details.physicalExamPrevData != null && details.physicalExamPrevData.length > 0){
          this.setState({
            physicalExamPrevData: details.physicalExamPrevData[0]
          })
        }

        if(details.fetalParamsData != null && details.fetalParamsData.length > 0){
          const {multipleFetalParamsData} = this.state;
          this.setState({
            multipleFetalParamsData: details.fetalParamsData[0]?.fetal_params
          
          })
        }


        if(details.physicalExamData != null && details.physicalExamData.length > 0){
          this.setState({
            physicalExamData: details.physicalExamData[0]
          })
        }else{
          this.setState({
            physicalExamData: null
          })
        }

        this.setState({
          details,
          constants: details.constants,
          field_status,
          dataLoading: false,
          physiotherapy_advised: details?.physiotherapyAdvised === null || details?.physiotherapyAdvised === 0 ? 2 : 1,
          admissionChecked: details?.admissionAdvised == 1 ? true : false,
          icu_days: details.icu_days > 0 ? details.icu_days : '',
          case_type: details.case_type,
          ward_days: details.ward_days > 0 ? details.ward_days : '',
          physioText: details?.physiotherapyComment ?? '',
          admissionComment: details?.admissionComment ?? '',
          isAdmissionAdvisedPriority: details.admissionAdvisedPriority == null || details.admissionAdvisedPriority == 0 ? false : true,
          admissionAdvisedDisabled: details?.admissionPayloadStatus == 1 ? true : false,
          followUpRadio,
          otherVal,
          follow_up_Others,
          follow_up_days,
          bookNowAllow: bookNowAllowFn(),
        },()=>{
          this.handleFollowupDate();
        })
      })
      .catch((err) => {
        console.log(err.data)
      })
  }

  handleInstructionDelete = async (id) => {
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.reference_id,
      instruction_id: id
    }
    await axios.get(PATIENT_INSTRUCTION_DELETE(PARAMS))
      .then(success => {
        if (success.data.status) {
          return true;
        }
      });
  }

  loadData = () => {
    let { constants, favourite, intuitive } = this.state;

    const drugsConstantsPromise = axios.get(DRUGS_CONSTANT);

    const testConstantsPromise = axios.get(LAB_TEST_CONSTANTS);

    let drug_params = { search: '' };
    if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
      drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
    } else {
      drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    drug_params.store_id = LS_SERVICE.get("STORE_ID");
    drug_params.facility_id = LS_SERVICE.get("FACILITY_ID");
    drug_params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
    const DrugsFavourite = LS_SERVICE.get("favouriteMedicines") || [];

    let test_params = {};
    if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
      test_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
    } else {
      test_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    if (APP_ENV == 'mhea') {
      test_params.active = true;
      test_params.facility_id = LS_SERVICE.get('FACILITY_ID');
      test_params.filter_service_type = 'I,P,O,OPP';
      test_params.enterpriseId = LS_SERVICE.get('ENTERPRISE_ID');
      test_params.doctor_id = LS_SERVICE.get('staff_id');
    }
    const testsFavouritePromise = axios.post(LAB_TEST_SEARCH, qs.stringify(test_params));

    const intuitiveConstantsPromise = LS_SERVICE.get("intuitive_constants")?.data?.details ? LS_SERVICE.get("intuitive_constants")?.data?.details : axios.get(INTUITIVE_CONSTANTS);

    axios.all([drugsConstantsPromise, testConstantsPromise, testsFavouritePromise, intuitiveConstantsPromise]).then(
      axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseFour = responses[2];
        const responseFive = LS_SERVICE.get("intuitive_constants")?.data?.details ? LS_SERVICE.get("intuitive_constants")?.data : responses[3].data;
        LS_SERVICE.set("intuitive_constants", {data: responseFive})

        if (responseOne.data.hasOwnProperty("data") === true) {
          constants = responseOne.data.data;
        }

        if (responseTwo.data.hasOwnProperty("data") === true) {
          constants["radiologyViews"] = responseTwo.data.data;
        }

        favourite.drugs = DrugsFavourite

        if (responseFour.data.hasOwnProperty("test_list") === true) {
          var testList = responseFour.data.test_list;
          var finaltestlist = []
          for (var index = 0; index < testList.length; index++) {
            if (testList[index].patientClassOp == true) {
              finaltestlist.push(testList[index])
            }
          }
          favourite.tests = finaltestlist;
        }
        else {
          favourite.tests = []
        }

        if (responseFive.hasOwnProperty("details") === true) {
          intuitive = responseFive.details;
        }

        this.subjectiveChange();

        this.checkAdmissionCaseTypeFlag();

        this.setState({
          constants: constants,
          favourite: favourite,
          intuitive: intuitive
        });
      })
    );
  };

  handleOnChange = (e, callBack = null) => {
    const { name, value } = e.target;
    console.log("other-select-date", e.target)
    this.setState({
      [name]: value,
      bookNowAllow: false,
    }, () => {
      this.handleFollowupDate();
      if (callBack !== null) {
        callBack();
      }
    })
  }

  handleFollowupDate=()=>{
    // console.log("handleFollowupDate-other-select-date-follow_up_days", this.state.follow_up_days)
    // console.log("handleFollowupDate-other-select-date-followUpRadio", this.state.followUpRadio)
    // console.log("handleFollowupDate-other-select-date-otherVal", this.state.otherVal)
    // console.log("handleFollowupDate-other-select-date-follow_up_type", this.state.follow_up_Others)

    if(this.state.followUpRadio!=null||this.state.otherVal!=null){
      this.setState({
        follow_up_date: dayjs().add(this.state.followUpRadio!=null&&this.state.followUpRadio!='-1'?parseInt(this.state.followUpRadio, 10):parseInt(this.state.otherVal, 10), this.state.follow_up_Others.toLowerCase()=="days"?"day":this.state.follow_up_Others.toLowerCase()=="weeks"?"week":this.state.follow_up_Others.toLowerCase()=="months"?"month":this.state.follow_up_Others.toLowerCase()=="years"?"year":"days")
      }, () => {
        // console.log("handleFollowupDate-other-select-date-follow_up_date", this.state.follow_up_date)
        this.handleFollowUpApi();
      })
    }
  }


  followupDatePicker = (dateDays, dateString) => {
    // console.log("followupDatePicker-dateDays", dateDays)
    // console.log("followupDatePicker-dateString", dateString)

    const currentDate = dayjs();
    const selectedDate = dayjs(dateString);
    const diffDays = selectedDate.isSame(currentDate, 'day')? 0 : selectedDate.diff(currentDate, 'day') + 1;

    this.setState({
      follow_up_date: dateDays,
      follow_up_days: diffDays,
      followUpRadio: [3, 5, 7, 15, 30, 60].includes(diffDays)? diffDays : -1,
      otherVal: diffDays,
      follow_up_Others: 'Days',
    }, () => {
      // console.log("handleFollowupDate-other-select-date-follow_up_days", this.state.follow_up_days)
      // console.log("handleFollowupDate-other-select-date-followUpRadio", this.state.followUpRadio)
      // console.log("handleFollowupDate-other-select-date-otherVal", this.state.otherVal)
      // console.log("handleFollowupDate-other-select-date-follow_up_type", this.state.follow_up_Others)
      // console.log("handleFollowupDate-other-select-date-follow_up_date", this.state.follow_up_date)
      this.handleFollowUpApi();
    })
  }

  viewMedicineData() {
    if (this.medicineCollapseRef.current?.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
  }

  viewTestData() {
    if (this.testCollapseRef.current?.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
  }

  loadInstructionData = () => {
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    const doctor_id = LS_SERVICE.get('staff_id');
    axios.get(`${FETCH_SUPPORTING_MATERIAL}?formType=all&referenceId=${this.state.reference_id}&referenceType=${REFERENCE_TYPE}`
    ).then(success => {
      if (success.data.supportingFiles) {
        this.setState({
          allSupportingMaterial: success.data.supportingFiles,
          supportingMaterial: success.data.supportingFiles.filter(file => file.selected)
        })
      }
    }).catch(err => console.log(err))

    axios.get(`${INSTRUCTION_SEARCH}?reference_type=${REFERENCE_TYPE}&reference_id=${this.state.reference_id}&doctor_id=${doctor_id}`
    ).then(success => {
      var selectedInstructions = [];
      for (var selectedInstruction of success.data.data.details) {
        if (selectedInstruction.selected == true) {
          selectedInstructions.push(selectedInstruction)
        }
      }
      // console.log("selectedInstructions",selectedInstructions,this.instructionCollapseRef,this.medicineComponentRef);
      this.setState({
        instructionList: selectedInstructions,
      });
    }).catch(err => console.log(err))
  }

  openMedicineComponent = async () => {
    this.handleTabClick('plan_medicine')
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
  }

  openTestComponent = async () => {
    this.handleTabClick('plan_test')
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
  }

  updateInstructionFromParent = async (data) => {
    await this.setState({
      isTestShow: false,
      isMedShow: false,
    });
    this.setState({
      isTestShow: true,
      isMedShow: true,
    });
    if (this.medicineCollapseRef.current.classList.contains("collapsed")) {
      this.medicineCollapseRef.current?.click();
    }
    if (this.testCollapseRef.current.classList.contains("collapsed")) {
      this.testCollapseRef.current?.click();
    }
    this.loadInstructionData();
  }

  subjectiveChange = async (data) => {
    var intuitive_data = {};
    var cps;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    var cc_keyword = this.state.cc_keyword;
    var internal_notes_keyword = this.state.internal_notes_keyword;
    var clinical_notes_keyword = this.state.clinical_notes_keyword;
    if (!data || data == 'cc_hpi') {
      cc_keyword = [];
      var cc_hpi = await axios.get(SUBJECTIVE_INFRMATION_CHIEF_COMPLAINTS({ reference_type: REFERENCE_TYPE, reference_id: this.state.reference_id }));
      var cc_hpi_array = cc_hpi?.data?.data?.details || [];
      for (var cc_hpi_data of cc_hpi_array) {
        if (cc_hpi_data?.description && cc_hpi_data?.description != null && cc_hpi_data?.description != '') {
          let regex_aplhabet = /[a-zA-Z0-9?]/; 
          const isValidAlphabet = regex_aplhabet.test(cc_hpi_data?.description);
          if (cc_hpi_data?.subjective_id == 0 && cc_hpi_data?.description.length >2 && isValidAlphabet) {
            this.setState({
              disableCompleteButton: false
            });
          }
          var cc_keyword_full = this.cleanKeyword(cc_hpi_data.description)
          if (cc_keyword_full?.length > 0) {
            cc_keyword = cc_keyword.concat(cc_keyword_full);
          }
        }
        else if (cc_hpi_data?.subjective_id == 0) {
          this.setState({
            disableCompleteButton: true
          });
        }
      }
      // const { medicineDataEmpty } = this.state;
      // if (medicineDataEmpty == true) {
      //   this.setState({
      //     disableCompleteButton: true,
      //     disableConfirmButton: true
      //   });
      // }
      this.setState({
        cc_keyword: cc_keyword
      });
    }
    if (!data || data == 'internal_notes') {
      internal_notes_keyword = [];
      var internal_notes = await axios.get(SUBJECTIVE_INFRMATION_INTERNAL_NOTES({ reference_type: REFERENCE_TYPE, reference_id: this.state.reference_id }));
      if (internal_notes?.data?.data?.internal_notes && internal_notes?.data?.data?.internal_notes != null && internal_notes?.data?.data?.internal_notes != '') {
        var internal_notes_keyword_full = this.cleanKeyword(internal_notes?.data?.data?.internal_notes)
        if (internal_notes_keyword_full?.length > 0) {
          internal_notes_keyword = internal_notes_keyword.concat(internal_notes_keyword_full)
        }
      }
      this.setState({
        internal_notes_keyword: internal_notes_keyword
      });
    }
    if (!data || data == 'clinical_notes') {
      clinical_notes_keyword = [];
      var clinical_notes = await axios.get(SUBJECTIVE_INFRMATION_CLINICAL_NOTES({ reference_type: REFERENCE_TYPE, reference_id: this.state.reference_id }));
      if (clinical_notes?.data?.data?.clinical_notes && clinical_notes?.data?.data?.clinical_notes != null && clinical_notes?.data?.data?.clinical_notes != '') {
        var clinical_notes_keyword_full = this.cleanKeyword(clinical_notes?.data?.data?.clinical_notes)
        if (clinical_notes_keyword_full?.length > 0) {
          clinical_notes_keyword = clinical_notes_keyword.concat(clinical_notes_keyword_full)
        }
      }
      this.setState({
        clinical_notes_keyword: clinical_notes_keyword
      });
    }
    if (this.state.ss_configuration.some(o => (o?.id === 4 && o?.configuration?.[this.state.consult_type + '_screen']?.selected == 'Y' && o?.custom?.value == 'Related'))) {
      await this.setState({
        cpDiagnosisType: 'Related'
      })
      var keyword = cc_keyword.concat(internal_notes_keyword, clinical_notes_keyword);
      var keyword_string = keyword.join()

      var CP_PARAMS = {
        hospital_id: LS_SERVICE.get("slot_hospital_id"),
        speciality_id: LS_SERVICE.get("speciality_id"),
        logined_id: LS_SERVICE.get("staff_id"),
        login_role: 'doctor',
        hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
      }
      cps = await axios.post(TEMPLATE_FILTER, qs.stringify(CP_PARAMS));
      if (keyword_string?.split(' ')?.length >= 1) {
        var uniq = [...new Set(keyword)];
        var filtered_keyword= []
        for (var kdata of uniq){
          if(kdata?.length >= 3){
            filtered_keyword.push(kdata)
          }
        }
        var PARAMS = {
          logined_id: LS_SERVICE.get("staff_id"),
          hospital_id: LS_SERVICE.get("slot_hospital_id"),
          speciality_id: LS_SERVICE.get("speciality_id"),
          hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
          login_role: 'doctor',
          keywords: filtered_keyword
        }
        intuitive_data = await axios.post(INTUITIVE_DIAGNOSIS_AND_CP, qs.stringify(PARAMS));

        await this.setState({
          suggestedCP: intuitive_data?.data?.data?.templates || [],
          suggestedDiagnosis: intuitive_data?.data?.data?.diagnosis || [],
        })
      }
      else {
        await this.setState({
          suggestedCP: [],
          suggestedDiagnosis: [],
        })
      }
    }
    else if (this.state.ss_configuration.some(o => (o?.id === 4 && o?.configuration?.[this.state.consult_type + '_screen']?.selected == 'Y' && o?.custom?.value == 'Favorites'))) {
      var CP_PARAMS = {
        hospital_id: LS_SERVICE.get("slot_hospital_id"),
        speciality_id: LS_SERVICE.get("speciality_id"),
        logined_id: LS_SERVICE.get("staff_id"),
        login_role: 'doctor',
        hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
      }
      var intuitive_data = {
        data: {
          data: {
            templates: [],
            diagnosis: []
          }
        }
      }
      cps = await axios.post(TEMPLATE_FILTER, qs.stringify(CP_PARAMS));
      if (cps?.data?.data?.templates && cps?.data?.data?.templates?.length > 0) {
        for (var cp of cps?.data?.data?.templates) {
          if (cp?.is_favorite == '0' || cp?.is_favorite == null || cp?.is_favorite == '') {
            // DO NOTHING
          }
          else {
            var fav_doc = cp?.is_favorite?.split(',').map(Number);
            if (fav_doc.includes(LS_SERVICE.get("staff_id"))) {
              intuitive_data.data.data.templates.push(cp)
            }
          }
        }
      }
      await this.setState({
        cpDiagnosisType: 'Favorites'
      })

      var fav_diagnosis = await axios.post(SEARCH_DIAGNOSIS);
      intuitive_data.data.data.diagnosis = fav_diagnosis.data
      await this.setState({
        suggestedCP: intuitive_data?.data?.data?.templates || [],
        suggestedDiagnosis: intuitive_data?.data?.data?.diagnosis || [],
      })
    }
    var allCP = [];
    var otherCP = [];
    if (intuitive_data?.data?.data?.templates?.length > 0) {
      for (var cp of cps?.data?.data?.templates) {
        allCP.push(cp)
        if (intuitive_data?.data?.data?.templates.some(t => t.template_id === cp.template_id)) {
          // NOTHING TO DO
        }
        else {
          otherCP.push(cp)
        }
      }
    }
    else {
      if (cps?.data?.data?.templates?.length > 0) {
        allCP = cps?.data?.data?.templates;
        otherCP = cps?.data?.data?.templates;
      }
    }

    await this.setState({
      allCP: allCP,
      otherCP: otherCP,
    })
  }

  cleanKeyword(keyword) {
    var finalKeyword = []

    // convert the whole keyword to smallcase
    keyword = keyword.toLowerCase();

    // remove leading/trailing whitespace and multiple spaces
    keyword = keyword.trim();
    keyword = keyword.replace(/[^a-zA-Z\,\s]/g, ",");
    keyword = keyword.replace(/\.+/g, ",");
    keyword = keyword.replace(/\s\s+/g, " ");
    keyword = keyword.replace(/\,\,+/g, ",");


    // remove excluded words
    for (var exclusion of this.state?.intuitive?.exclusion) {
      var regex_defined1 = new RegExp("\\W" + exclusion.word + "\\W", "g")
      keyword = keyword.replace(regex_defined1, " ");
      var regex_defined2 = new RegExp("^" + exclusion.word + "\\W", "g")
      keyword = keyword.replace(regex_defined2, " ");
      var regex_defined3 = new RegExp("\\W" + exclusion.word + "$", "g")
      keyword = keyword.replace(regex_defined3, " ");
    }

    // replace all dividers with comma
    for (var split of this.state.intuitive?.split) {
      if (split.type == 'word') {
        var regex_defined1 = new RegExp("\\W" + split.word + "\\W", "g")
        keyword = keyword.replace(regex_defined1, ",");
        var regex_defined2 = new RegExp("^" + split.word + "\\W", "g")
        keyword = keyword.replace(regex_defined2, ",");
        var regex_defined3 = new RegExp("\\W" + split.word + "$", "g")
        keyword = keyword.replace(regex_defined3, ",");
      }
      else if (split.type == 'symbol') {
        var regex_defined1 = new RegExp(split.word, "g")
        keyword = keyword.replace(regex_defined1, ",");
      }
      else if (split.type == 'escapechar') {
        var regex_defined1 = new RegExp(split.word, "g")
        keyword = keyword.replace(regex_defined1, ",");
      }
    }

    // replace unwanted spaces and commas
    keyword = keyword.trim();
    keyword = keyword.replace(/\s+\,+\s+/g, ",");
    keyword = keyword.replace(/\,+\s+/g, ",");
    keyword = keyword.replace(/\s+\,+/g, ",");

    // convert into array
    finalKeyword = keyword.split(/[,]+/);

    var filtered_keywords = finalKeyword.filter(element => {
      return element !== '';
    });

    // remove negetive values from keyword array
    var final_filtered_keyword = [];
    for (var filtered_keyword of filtered_keywords) {
      var negetive_included = 'N';
      for (var negetive of this.state.intuitive?.negetive) {
        var regex_defined1 = new RegExp("\\b" + negetive.word + "\\b", "g")
        if (filtered_keyword.search(negetive.word) >= 0) {
          negetive_included = 'Y'
          break;
        }
        else {
          negetive_included = 'N'
        }
      }
      if (negetive_included == 'N') {
        final_filtered_keyword.push(filtered_keyword)
      }
    }
    return final_filtered_keyword;
  }

  updateInstructionsData = async (data) => {
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.reference_id,
      type: 'instruction',
      instruction: data,
      supporting_materials: []
    }

    let instructionPromise;
    if (this.state.allowEdit) {
      instructionPromise = await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    }
    this.loadInstructionData();
    this.handleModalPopup(null, 'showInstructionModal');
  }

  handleEditInstruction = (inst) => {
    
    this.setState({
      actionText: 'Edit',
      editData: inst,
      showInstructionModal: true,
    },()=>{
      this.instructionFormRef.current.setFocus();
    });
  };

  beforeChange = (slick, currentSlide, nextSlide) => {
    let documentdata = this.state.patient_docments[currentSlide].src
    this.setState({
      documentURL: documentdata
    })
  }

  selectAllFiles = (e) => {
    this.setState({ downloadAllfiles: e.target.checked });
  }

  downloadFiles = (e) => {
    e.preventDefault();
    this.setState({
      download_file_loading: true
    })
    if (this.state.downloadAllfiles) {
      this.state.patient_docments.map((element, index) => {
        this.downloadDocument(element.src, index);
      });
    } else {
      this.downloadDocument(this.state.documentURL);
    }
  }

  downloadDocument = (file_url, index = '') => {
    let file_extention = file_url.split('.').pop();
    let file_name = "document_" + moment().format('D_MMM_YYYY') + index + "." + file_extention;
    axios.post(GET_ARRAY_BUFFER_FORM_URL, { file_url }, { responseType: 'arraybuffer' }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      let file = document.createElement('a');
      file.href = url;
      file.download = file_name;
      file.click();
      this.setState({
        download_file_loading: false
      })
    });
  }

  // For Modal popup
  handleModalPopup = (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
    if (e !== null)
      e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    },
      () => {
          const { viewDocumentModal } = this.state;
          this.subjectiveChange();
          if (viewDocumentModal) {
            this.carouselRef.current.goTo(data);
            let documentdata = this.state.patient_docments[data].src
            this.setState({
              documentURL: documentdata
            })
          }
  
          this.setState({
            actionText: "Add", editData: null, editIndex: -1
          }, () => {
            const { showVitalsModal, showAssessmentModal, showMedicineModal, showInstructionModal, showTestModal, deleteConfirmModal, showDrugAllergyModal, showDrugPolyPharmacyModal, showSupportingModal } = this.state;
            if (!showVitalsModal) {
              this.state?.vitalsConstants?.vitals_details?.map((v, k) => {
                this.setState({ [v.id]: null });
              });
  
              if (this.vitalFormRef?.current !== null) {
                this.vitalFormRef?.current?.resetFields();
              }
            } else {
  
              let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
              /* For dispalying fields on basis of dropdown select*/
              let defaultVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
              let selectedVitalGroup = Object.values(this.state.vitalsConstants.vital_groups).find(item => {
                let item_arr = [];
                item_arr = item.speciality_id != null ? item.speciality_id.split(',') : item_arr
                // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
                if (LS_SERVICE.get('speciality_id') && item_arr.indexOf(LS_SERVICE.get('speciality_id')) && item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
                  return true;
              });
  
              if (selectedVitalGroup == undefined) {
                selectedVitalGroup = defaultVitalGroup;
              }
  
              if (typeof selectedVitalGroup != 'undefined' && selectedVitalGroup != '') {
                seletctedSpecialityIdID = selectedVitalGroup.id
                this.setState({
                  seletctedSpecialityIdID: seletctedSpecialityIdID
                })
              }
              // let seletctedShowingVitalGroup1 = Object.values(this.state.constants.vital_groups).find(item => item.id == seletctedSpecialityIdID);
              let showingVitals = selectedVitalGroup.vital_sign;
  
              let displayShowingVitals1 = [];
              let displayVitals = [];
              showingVitals.map((v, k) => {
                displayShowingVitals1.push(v.id);
                displayVitals.push(v.vital_id);
              });
  
              displayVitals = displayVitals.toString();
  
              this.setState({
                displayShowingVitals: displayShowingVitals1,
                displayVitals: displayVitals,
                vitalsType: selectedVitalGroup.id,
                vitalsInput: showingVitals,
              })
  
              // orderVitalsService.init(displayShowingVitals1);
              let { patientHeight, patientWeight, patient } = this.state;
  
              if (patient.age > HOSPITALDEFAULTS.age) {
                if (this.vitalFormRef.current !== null) {
  
                  this.vitalFormRef.current.setFieldsValue({
                    V001: patientHeight,
                    V002: patientWeight
                  });
                  this.handleBmiCalculate();
                }
              }
              AntSelectLabels();
            }
  
            if (showAssessmentModal) {
  
              if (actionType === 'editAssessment') {
                this.setState({
                  actionText: "Edit",
                  editData: this.state.assessmentData[objId],
                  editIndex: objId
                })
                AntSelectLabels();
              }
  
            }
            if (showMedicineModal) {
              if (actionType === 'editMedicine') {
                this.setState({ actionText: "Edit" })
              }
  
              if (data !== null) {
                let editData = this.state.drugsData[objId];
                editData.previousEndDate = null;
                for (let index = (objId - 1); index >= 0; index--) {
                  if (this.state.drugsData[index].end_date != null && editData.previousEndDate == null)
                    editData.previousEndDate = this.state.drugsData[index].end_date;
                  if (editData.start_date) {
                    editData.end_date = moment(editData.start_date, 'YYYY-MM-DD').add(editData.number_of_days - 1, 'days').format('YYYY-MM-DD');
                  }
  
                }
                this.setState({
                  actionText: "Edit",
                  editData: editData,
                  editIndex: objId
                })
              }
            }
  
            if (showInstructionModal) {
              // const { allInstructionsData } = this.state;
              // this.setState({
              //   isNewInstruction: false,
              //   instructionOption: allInstructionsData
              // });
  
            }
  
  
            if (deleteConfirmModal) {
              // this.setState({ actionType, objId });
              // For Assessment
              if (actionType === 'deleteAssessment') {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete assessment?' });
              }
  
              // For Medicines
              if (actionType === 'deleteMedicine') {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete medicine?' });
              }
  
              // For Tests
              if (actionType === 'deleteTest') {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: 'Are you sure you want to delete test?' });
              }
  
            }
  
            if (showDrugAllergyModal) {
              this.setState({ allergiesMessage: data.message, deleteObjId: data.type == 'all' ? data.deleteObj : objId })
            }
  
            if (showDrugPolyPharmacyModal) {
              this.setState({ polyPharmacyMessage: data.message, deleteObjId: data.type == 'all' ? null : objId })
            }
  
  
          })
  
        }
        

      )

  }

  getMaterialData = (materialData) => {
    this.setState({ materialList: materialData });
  }

  updateSupportingMaterial = async (data) => {
    var selectedInstructions = [];
    for (var selectedInstruction of data) {
      if (selectedInstruction.selected == true) {
        selectedInstructions.push({
          id: selectedInstruction.id,
          file_name: selectedInstruction.fileName,
          file_url: selectedInstruction.fileUrl
        })
      }
    }
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.reference_id,
      type: 'sm',
      instruction: [],
      supporting_materials: selectedInstructions
    }
    if (this.state.allowEdit) {
      await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    }
    this.loadInstructionData();
  }

  enablePractice = async e => {
    e.preventDefault();
    practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      is_practicing = false;
      LS_SERVICE.delete('practicing');
    }
    this.setState({
      isPracticing: is_practicing,
    }, () => {
      this.handleModalPopup(null, 'isPracticingModal');
    });
  }

  handleRadioChange = (e, callBackFunName = "") => {
    e.preventDefault()
    const { isPracticing } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }

    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isEmrConfigAdmissionAdvised: false,
      radioChecked : false,
    }, () => {
      if (callBackFunName !== "") {
        if (callBackFunName == "admissionSave" && value == false) {
          this.setState({
            surgeryRequestRequired : false,
            surgeryRequired: false,
          });
        }
        if (callBackFunName == "admissionSave" && value == true) {
          this.getActiveAdmissionRequest();
        }
        this[callBackFunName]();
      }
    })
  }

  handleAdmissionAdvicedCheckbox = e => {
    const { isPracticing, isAdmissionAdvisedPriority } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }

    let { name, checked } = e.target;
    this.setState({
      [name]: checked,
      isAdmissionAdvisedPriority: !checked ? false : isAdmissionAdvisedPriority
    }, this.admissionSave)
  }

  handleAdmissionAdvicedComment = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    }, this.admissionSave)
  }

  admissionSave = () => {
    //this.setState({ disableConfirmButton: true });
    const { patient, admissionChecked, isAdmissionAdvisedPriority, admissionComment, icu_days, ward_days, case_type } = this.state;
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      admission_advised: admissionChecked ? 1 : 0,
      admission_comment: admissionComment,
      icu_days: icu_days,
      case_type: case_type,
      ward_days: ward_days,
      priority: isAdmissionAdvisedPriority ? 1 : 0
    }
    if (!admissionChecked) {
      this.setState({
        admissionComment: "",
        icu_days: "",
        case_type: null,
        ward_days: "",
      })
      PARAMS.admission_comment = "";
      PARAMS.icu_days = "";
      PARAMS.case_type = null;
      PARAMS.ward_days = "";
    }
    axios.post(MYHC_APPTYPE === WOODLANDS ? ADMIS_UPADTE : ADMISSION_UPDATE, qs.stringify(PARAMS))
      .then(res => {
        //this.setState({ disableConfirmButton: false });
      })
  }

  handleFollowUpRadio = (e) => {
    const { isPracticing } = this.state;

    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }
    
    const { value, title } = e.target;
    // console.log("follow_up_days====>",e.target);
    this.setState({
      followUpRadio: value,
      follow_up_days: title !== '-1' ? +title : title,
      bookNowAllow: false,
      otherVal:0,
      follow_up_Others:"Days",
    }, () => {
      const { follow_up_days } = this.state;
      if (follow_up_days === '-1') return;
      // this.handleFollowUpApi();
      this.handleFollowupDate();
    })

    // console.log("follow_up_days====>",this.state.follow_up_days, )
  }

  getActiveAdmissionRequest = async () => {
    const { patient } = this.state;
    let PARAMS = {
      RegistrationId: parseInt(patient.mhea_patient_id),
      EnterpriseId: parseInt(LS_SERVICE.get('ENTERPRISE_ID')),
      FacilityId: parseInt(LS_SERVICE.get('FACILITY_ID'))
    }
    axios.post(ACTIVE_ADMISSION_REQUEST, qs.stringify(PARAMS))
      .then(res => {
        if (res.data?.data && res.data.data.length > 0) {
          this.setState({ admissionListData: res.data.data, viewAdmissionGrid: true });
        }
      })
  }

  getCaseTypeList = async () => {
    let PARAMS = {
      active: 1,
      enterpriseId: parseInt(LS_SERVICE.get('ENTERPRISE_ID'))
    }
    axios.post(CASE_TYPE_LIST, qs.stringify(PARAMS))
      .then(res => {
        if (res.data?.data && res.data.data.length > 0) {
          this.setState({ caseTypeList: res.data.data });
        }
      })
  }


  handleFollowUpApi = _ => {
    const { follow_up_days, followUpRadio, patient, follow_up_Others, otherVal } = this.state;

    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: LS_SERVICE.get('reference_type'),
      patient_id: patient.patient_id,
      follow_up_days: followUpRadio != '-1' ? followUpRadio : +otherVal,
      follow_up_type: followUpRadio != '-1' ? follow_up_days : DURATION_TYPE_OBJECT[follow_up_Others.toLowerCase()]
    }

    axios.post(FOLLOWUP_UPDATE, qs.stringify(PARAMS));
    // console.log("handleFollowUpApi---> ", PARAMS);

  }

  fetchHospitalDetails = async () => {
    const hospitalId = parseInt(LS_SERVICE.get("slot_hospital_id"));
  
    try {
      const response = await axios.post(HOSPITAL_LISTS, {
        hospitalId: hospitalId
      });
    
      if (response && response.status === 200) {
        let data = response.data.data;
        this.setState({
          isEmrConfigAdmissionAdvised: data.admission_advice_mandatory === "Y" ? true : false,
          isEMRButtonDisabled: data.admission_advice_mandatory === "Y" ? true : false,
          sendTestOrder: data.send_test_order
        });  
        // Move the return statement here
        return data;
      }
    } catch (error) {
      console.error('Error fetching hospital details:', error);
      throw error;
    }
  }
  
  handleToggleAdmission = (e) => {
    if (this.state.admissionAdvisedDisabled) {
      return false;
    }
    this.setState({
      isAdmissionAdvisedPriority: e
    }, this.admissionSave)

  }

  goToANCCard = async (e) => {
    e.preventDefault()
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    const { patient, hospital_id } = this.state;
    this.props.history.push({
      pathname: `/patient/anc-card/${hospital_id}/${patient.patient_id}/${REFERENCE_TYPE}/${patient.qms_token_id}`,
      state: { patient }
    })
  };

  handleViewPresc = async (e) => {
    // var selectedInstructions = [];
    // for(var selectedInstruction of this.state.materialList){
    //   if(selectedInstruction.selected == true){
    //     selectedInstructions.push({
    //       id: selectedInstruction.id,
    //       file_name: selectedInstruction.fileName,
    //       file_url: selectedInstruction.fileUrl
    //     })
    //   }
    // }
    // const PARAMS = {
    //   reference_type: REFERENCE_TYPE,
    //   reference_id: this.state.reference_id,
    //   type: 'sm',
    //   instruction: [],
    //   supporting_materials: selectedInstructions
    // }
    // let instructionPromise;
    // if (this.state.allowEdit){
    //   instructionPromise = await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    // }
    e.preventDefault()
    this.setState({ saveAsCareProtocolModal: true});
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    const { patient, hospital_id } = this.state;
    // this.props.history.push({
    //   pathname: `/patient/todays-visit/view-prescriptionss/${hospital_id}/${patient.patient_id}/${REFERENCE_TYPE}/${patient.qms_token_id}`,
    //   state: { patient }
    // })
    // const hospital_id = LS_SERVICE.get("slot_hospital_id");
    const PARAMS = [
      {
        reference_type: REFERENCE_TYPE,
        patient_id: patient.patient_id,
        reference_id: patient.qms_token_id,
        hospital_id,
        type: 'SS'
      },
      {
        reference_type: REFERENCE_TYPE,
        reference_id: patient.qms_token_id,
      },
    ];

    PARAMS[0].store_id = LS_SERVICE.get("STORE_ID");
    PARAMS[0].facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS[0].enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");

    const SOAP_DETAIL_REQ = Axios.post(SOAP_DETAILS, qs.stringify(PARAMS[0]));
    const PDF_CONFIG_VITAL_GRAPH_REQ = Axios.get(PDF_CONFIG_VITALS_GRAPH(PARAMS[1]));

    Axios.all([SOAP_DETAIL_REQ, PDF_CONFIG_VITAL_GRAPH_REQ]).then(
      Axios.spread(async (...res) => {
        const { details, field_status } = res[0].data;
        this.setState({ supportingFile: details.supportingMaterials });
        const { graph_settings } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { key_findings_graph_settings } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { growthchart_age, growthchart_type } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { fetal_params_graph_settings } = res[1].data.data.details !== null ? res[1].data.data.details : "";
        const { clinical_note_ids } = res[1].data.data.details !== null ? res[1].data.data.details : "";

        if (details.subjectives === null) {
          details.subjectives = [
            {
              subjective_title: "Chief Complaints & HPI",
              description: "",
              subjective_id: 0,
            },
            {
              subjective_title: "Physical Examination",
              description: "",
              subjective_id: 2,
            },
          ];
        }

        field_status.subjective?.map((item, i) => {
          this.setState({
            [item.type + "Disabled"]: true,
            [item.type + "Value"]: details.subjectives[i] ? details.subjectives[i].description : "",
          });
        });

        details.constants.vitals_details = details.constants.vitalsDetails;
        details.constants.vitals_range = details.constants.vitalsRange;

        let assessmentTempArray = [];
        let addedAssessmentList = [];
        if (details.assessments && details.assessments.length > 0) {
          details.assessments.map((data, i) => {
            var tempAssessmentObj = {
              description: data?.diagnosisInfo?.diagnose_description,
              diagnosis_id: data?.diagnosisId,
              icd_code: data?.diagnosisInfo?.icd_code,
              id: data.id,
              image_comment: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
              image_uploaded: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
              stage: data.stage,
              type: data.type,
              status: data.status,
              diagnosis_notes: data.diagnosisNotes,
            };
            assessmentTempArray.push(tempAssessmentObj);
            addedAssessmentList.push(tempAssessmentObj.diagnosis_id);
          });
        }

        let addedDrugsList = [];
        let drugsData = details.medicines ? details.medicines : [];

        if (drugsData && drugsData.length > 0) {
          drugsData.map((v, k) => {
            drugsData[k].prescription_drug_id = v.prescriptionDrugId;
            drugsData[k].prescription_drug_active = v.prescriptionDrugActive;
            delete drugsData[k].prescriptionDrugId;
            delete drugsData[k].prescriptionDrugActive;
            addedDrugsList.push(v.drug_id);
          });
        }

        let addedTestList = [];
        if (details.tests && details.tests.length > 0) {
          details.tests.map((v, k) => {
            addedTestList.push(v.test_id);
          });
        }

        this.setState({
          isEventBased: details?.prescriptionDrug?.isEventBased ? details?.prescriptionDrug?.isEventBased : 'N'
        })
        details.constants.vitals_range = details.constants.vitalsRange;
        let seletctedSpecialityIdID = DEFAULTVITALGROUPID;
        //Finding emr_vital_group_id of emr_vital_group table using speciality id of doctor
        let selectedVitalGroup = Object.values(details.constants.vitalGroups).find((item) => {
          let item_arr = [];
          item_arr = item.speciality_id != null ? item.speciality_id.toString().split(",") : item_arr;
          // if (item_arr.indexOf(LS_SERVICE.get('speciality_id').toString()) != -1)
          if (
            LS_SERVICE.get("speciality_id") &&
            item_arr.indexOf(LS_SERVICE.get("speciality_id")) &&
            item_arr.indexOf(LS_SERVICE.get("speciality_id").toString()) != -1
          )
            return true;
        });
        if (selectedVitalGroup != undefined) {
          seletctedSpecialityIdID = selectedVitalGroup.id;
        }
        let seletctedShowingVitalGroup = Object.values(details.constants.vitalGroups).find((item) => item.id == seletctedSpecialityIdID);

        const remainingVitals = details.constants.vitals_details.filter(
          (elem) => !seletctedShowingVitalGroup.vital_sign.find(({ vital_code }) => elem.vital_code === vital_code)
        );

        let obs_history_list = [];
        if (details.obsHistory != null && details.obsHistory.length > 0) {
          details.obsHistory.forEach(ele => {
            let obj = { id: ele.id, pregnancy_year: ele.year, mode_of_conception: ele.mode, mode_text: this.state.conception_options.filter(x => x.val == ele.mode)[0].label, pregnancy_type: ele.pregnancyType, previous_births: ele.previous_births, indication: ele.indication, remarks: ele.remarks };

            if (ele.pregnancyType == '2') {
              obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label + '(' + ele.previous_births.length + ')';
              let prev_del_types = [];
              let prev_outcomes = [];
              ele.previous_births.forEach(el => {
                if (el.deliveryType) {
                  let ob_del = { delivery_type: el.deliveryType, count: 1 };
                  let index_del = prev_del_types.findIndex(m => m.delivery_type === el.deliveryType);
                  if (index_del === -1) {
                    prev_del_types.push(ob_del)
                  } else {
                    prev_del_types[index_del].count = prev_del_types[index_del].count + 1;
                  }
                }

                let ob_outcome = { outcome: el.outcome, count: 1 };
                let index_outcome = prev_outcomes.findIndex(m => m.outcome === el.outcome);
                if (index_outcome === -1) {
                  prev_outcomes.push(ob_outcome)
                } else {
                  prev_outcomes[index_outcome].count = prev_outcomes[index_outcome].count + 1;
                }
              });
              let delivery_type_text_arr = [];
              prev_del_types.forEach(e => {
                delivery_type_text_arr.push(this.state.delivery_options.filter(x => x.val == e.delivery_type)[0].label + '(' + e.count + ')');
              });
              let outcome_text_arr = [];
              prev_outcomes.forEach(e => {
                outcome_text_arr.push(this.state.outcome_options.filter(x => x.val == e.outcome)[0].label + '(' + e.count + ')');
              });

              obj.delivery_type_text = delivery_type_text_arr.join(',');
              if (obj.delivery_type_text == '') {
                obj.delivery_type_text = '-';
              }
              obj.outcome_text = outcome_text_arr.join(',');
            } else {
              obj.pregnancy_type_text = this.state.pregnancy_options.filter(x => x.val == ele.pregnancyType)[0].label;
              if (ele.previous_births[0].deliveryType) {
                obj.delivery_type_text = this.state.delivery_options.filter(x => x.val == ele.previous_births[0].deliveryType)[0].label;
              } else {
                obj.delivery_type_text = '-';
              }
              obj.outcome_text = this.state.outcome_options.filter(x => x.val == ele.previous_births[0].outcome)[0].label;
            }
            obs_history_list.push(obj);
          });
        }

        let gpale_data = details.obsGpale;
        if (gpale_data) {
          let obj = { gravidity: gpale_data.gravidity, parity: gpale_data.parity, abortion: gpale_data.abortion, live_birth: gpale_data.liveBirth, ectopic: gpale_data.ectopic };
          await this.setState({
            gpale_values: obj
          })
        }

        await this.setState({
          details,
          field_status,
          constants: details.constants,
          showingVitals: seletctedShowingVitalGroup.vital_sign,
          vitalsConstant: remainingVitals,
          subjectiveData: details.subjectives,
          subjectiveClinicalData: details.subjectives_clinical,
          assessmentData: assessmentTempArray,
          addedAssessmentList: addedAssessmentList,
          drugsData: drugsData,
          addedDrugsList: addedDrugsList,
          testData: details.tests,
          addedTestList: addedTestList,
          isAdmissionAdvisedPriority: details.admissionAdvisedPriority === null || details.admissionAdvisedPriority === 0 ? false : true,
          admissionChecked: details?.admissionAdvised !== null && details.admissionAdvised == 1 ? true : false,
          admissionComment: details?.admissionComment ?? "",
          physiotherapy_advised: details.physiotherapyAdvised ? 1 : 2,
          physioText: details.physiotherapyComment,
          admissionText: details.admissionComment,
          isDataFetching: false,
          chartGraphSettings: graph_settings != undefined && graph_settings !== null && graph_settings !== "" ? graph_settings : "",
          keyFindingsChartGraphSettings:
            key_findings_graph_settings != undefined && key_findings_graph_settings !== null && key_findings_graph_settings !== ""
              ? key_findings_graph_settings
              : "",
          chartGraphSettingsFp: fetal_params_graph_settings != undefined && fetal_params_graph_settings !== null && fetal_params_graph_settings !== "" ? fetal_params_graph_settings : "",
          growthChartSettings:
            growthchart_age != undefined && growthchart_type != undefined && growthchart_age !== null && growthchart_type !== null
              ? { growthchart_type, growthchart_age }
              : {},
          clinicalNoteIds: clinical_note_ids != undefined && clinical_note_ids !== null && clinical_note_ids !== "" ? clinical_note_ids.split(',') : [],
          // chartData: details.chartdata,
          chartData: details.chartdatanew,
          chartDataLoaded: true,
          chartDataFp: details.chartdata_fp,
          chartDataLoadedFp: true,
          bloodSugarChartData: details.bloodSugarData,
          vitalsRangeWithId: details.constants.vitalsRange,
          KeyFindingsConstants: details.constants.findingGroupData ? Object.values(details.constants.findingGroupData) : [],
          obsHistoryList: obs_history_list,
          phyExaminationList: details.phyExamination,
          phyExaminationFpList: details.phyExaminationFp,
          current_pregnancy: details.obsCurrentPreg,
          latest_menst_hist: details.menstHistoryLatest
        });
        if (this.state.growthChartSettings.growthchart_type && this.state.growthChartSettings.growthchart_age) {
          this.setState({
            gender: this.state.patientData.gender == "Female" ? "girls" : "boys",
          });
          if (this.state.patientData.cmonthsage != undefined && this.state.patientData.cmonthsage < 24 && this.state.patientData.cmonthsage >= 0) {
            await this.setState((prevState) => {
              let prevItems = { ...prevState.patientData };
              prevItems.monthsage = prevItems.cmonthsage;
              return { patientData: prevItems };
            });
          }
        }
        

      })
    );
  };

  templateSubmit = () => {
    this.setState({ saveAsCareProtocolModal: false });
    let { assessmentData, drugsData, testData, details, subjectiveData, subjectiveClinicalData, subjective_custom, parent_customdata } = this.state;
    let parent_custom_field_data = {
      custom_parent_field_1: parent_customdata.parent_custom1,
      custom_parent_field_2: parent_customdata.parent_custom2,
      custom_parent_field_3: parent_customdata.parent_custom3,
      custom_parent_field_4: parent_customdata.parent_custom4,
      custom_parent_field_5: parent_customdata.parent_custom5,
  }

  let child_custom_field_data = {
      custom_child_field_1: subjective_custom.custom1,
      custom_child_field_2: subjective_custom.custom2,
      custom_child_field_3: subjective_custom.custom3,
      custom_child_field_4: subjective_custom.custom4,
      custom_child_field_5: subjective_custom.custom5,
  }
    let templateAssessments = [];
    assessmentData.forEach((element, i) => {
      templateAssessments.push({
        diagnosis_id: element.diagnosis_id,
        text: element.description,
        code: element.icd_code,
        type: element.type,
        stage: element.stage,
        status: element.status,
        image_path: element.image_uploaded,
        image_comment: element.image_comment,
        diagnosis_notes: element.diagnosis_notes,
      });
    });

    let templateDrugs = [];
    var formulary_medicines = drugsData.filter(function (el) {
      return el.medicineType == 'F'
    });
    formulary_medicines.forEach((element, i) => {
      delete element.prescriptionId;
      delete element.prescriptionDrugId;
      delete element.prescription_drug_id;
      delete element.drug_generic;
      delete element.stopped_by;
      delete element.doctor_name;
      delete element.updated_at;
      delete element.medicineType;
      delete element.drug_duration_time;
      if (element.active == 'Y' && element.prescription_drug_active == 'Y') {
        templateDrugs.push(element);
      }
      delete element.prescription_drug_active;
      delete element.active;
    });

    let templateTests = [];
    var formulary_tests = testData.filter(function (el) {
      return el.test_type == 'F'
    });
    formulary_tests.forEach((element, i) => {
      delete element.is_diabetic;
      delete element.is_metformin;
      delete element.risk;
      delete element.test_type;
      if (element.test_id.toString().length < 10) {
        templateTests.push(element);
      }
    });

    let templateInstructions = [];
    if (details.instructions !== null) {
      details.instructions.forEach((element, i) => {
        templateInstructions.push({
          id: element.instruction_id,
          instruction: element.instruction,
        });
      });
    }

    let templateMaterials = [];
    if (details.supportingMaterials !== null) {
      details.supportingMaterials.forEach((element, i) => {
        templateMaterials.push({
          id: element.material_id,
          fileName: element.file_name,
          fileUrl: element.file_url 
        });
      });
    }

    var params = {
      name: this.name,
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      owner_id: LS_SERVICE.get("staff_id"),
      logined_id: LS_SERVICE.get("staff_id"),
      diagnosis_data: templateAssessments,
      drugs_data: formulary_medicines,
      tests_data: templateTests,
      instruction: templateInstructions.length == 0 ? [] : templateInstructions,
      supporting_material_data : templateMaterials,
      chief_complaints: subjectiveData[0].description,
      physical_examination: subjectiveData[1].description,
      parent_custom_field_data: parent_custom_field_data,
      child_custom_field_data: child_custom_field_data,
      clinical_notes:subjectiveClinicalData.length > 0 ? subjectiveClinicalData[0]?.description : null ,
      login_role:( LS_SERVICE.get("user_type") == USERTYPES.superadmin ||  LS_SERVICE.get("user_type") == USERTYPES.admin)?'':'doctor',
      hospital_master: 'N', 

    };
    axios
      .post(CREATE_TEMPLATE, qs.stringify(params))
      .then((success) => {
        this.setState({templateName: ""})
        if (success.data.status) {
          notification.success({
            message: success.data.message,
            placement: "topRight",
          });
        }else{
          notification.error({
            message: success.data.message,
            placement: "topRight",
          });
        }
      })
      .catch((err) => 
      console.log(err));
  };

  onChangeTemplateName = (e) => {
    this.name = e.target.value;
    if (e.target.value.length > 0) {
      this.setState({
        savedisabled: false,
        templateName: e.target.value
      });
    } else{
      this.setState({ savedisabled: true });
    }
  };

  handleCompleteAppointment = async () => {
    const { patient, hospital_id, isPracticing, reviewNotes, reviewTxt, surgeryRequestRequired, surgeryRequired, gyaneData, physicalExamData,multipleFetalParamsData, admissionChecked, case_type} = this.state;

    if (this.state.admission_advice_validation == 'true') {
      if (admissionChecked == true) {
        if (case_type == null || case_type == 0) {
          this.setState({
            case_type_error: true,
          });
          notification.error({
            message: 'Case type  is mandatory, Please select as applicable.',
            placement: 'topRight'
          });
          return false;
        }
      }
      if (surgeryRequired && surgeryRequestRequired) {
        notification.error({
          message: 'Kindly add atleast one surgery procedure or surgery request for the admission.',
          placement: 'topRight'
        });
        return false;
      }
    }
    else
    {
      if (surgeryRequestRequired) {
        notification.error({
          message: 'Kindly add planned surgery details for the admission.',
          placement: 'topRight'
        });
        return false;
      }
    }

    this.setState({
      isPrintLoader: true,
    });

    // var selectedInstructions = [];
    // for(var selectedInstruction of this.state.materialList){
    //   if(selectedInstruction.selected == true){
    //     selectedInstructions.push({
    //       id: selectedInstruction.id,
    //       file_name: selectedInstruction.fileName,
    //       file_url: selectedInstruction.fileUrl
    //     })
    //   }
    // }
    // const PARAMS = {
    //   reference_type: REFERENCE_TYPE,
    //   reference_id: this.state.reference_id,
    //   type: 'sm',
    //   instruction: [],
    //   supporting_materials: selectedInstructions
    // }
    // let instructionPromise;
    // if (this.state.allowEdit){
    //   instructionPromise = await axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));
    // }

    await this.vitalAndKfComponentRef?.current?.storeVitalDetails();
    await this.chiefComponentRef?.current?.handleSubjectiveFormSubmit("Print&Complete");
    await this.saveCustomSubjectiveOnPrint();
    await this.saveCustomParentSubjectiveOnPrint();
    await this.clinicalNotesRef?.current?.handleSubjectiveFormSubmit();
    await this.internalNotesRef?.current?.handleSubjectiveFormSubmit();
    this.admissionSave();

    if (!this.state.allowEdit) {
      this.props.history.push({
        pathname: `/patient-queue/${hospital_id}`,
      });
      return;
    }

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    var ipd_slots = LS_SERVICE.get("ipd_slots")
    var commonfacilityName = ipd_slots.find(item => item?.hospitalId == LS_SERVICE.get("FACILITY_ID"));

    const params = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: patient.qms_token_id,
      patient_id: patient.patient_id,
      doctor_id: patient.doctor_id,
      hospital_id,
      print_pdf: 1,
      type: 'SS',
      store_id: LS_SERVICE.get("STORE_ID"),
      facility_id: commonfacilityName?.facilityId || LS_SERVICE.get("FACILITY_ID"),
      facility_name: commonfacilityName?.facilityName || LS_SERVICE.get("FACILITY_NAME"),
      enterprise_id: LS_SERVICE.get("ENTERPRISE_ID"),
      enterprise_name: LS_SERVICE.get("ENTERPRISE_NAME"),
      USER_NAME: LS_SERVICE.get('staff_name'),
      // speciality_id: LS_SERVICE.get("speciality_id"),
      speciality_id: LS_SERVICE.get('call_patient_detail').speciality_id || null,
      gender: LS_SERVICE.get('call_patient_detail').gender || null,
      fromfacility: commonfacilityName?.hospitalName || LS_SERVICE.get("FACILITY_NAME"),
      fromfacilityid: commonfacilityName?.hospitalId || LS_SERVICE.get("FACILITY_ID"),
      //review_notes: reviewNotes == '' ? reviewTxt : reviewNotes
      review_notes: reviewTxt,
      cycle_length:gyaneData.cycle_length,
      lmp:gyaneData.lmp == "Invalid date" ? null : gyaneData.lmp,
      edd_by_lmp:gyaneData.edd_by_lmp == "Invalid date" ? null : gyaneData.edd_by_lmp,
      corrected_edd:gyaneData.corrected_edd == "Invalid date" ? null : gyaneData.corrected_edd,
      pregnant:gyaneData.pregnant,
      is_checked_aph: gyaneData.is_checked_aph,
      is_confidential: gyaneData.is_confidential ,
      mother_fetal_printwithprescription: gyaneData.mother_fetal_printwithprescription,
      is_sexual_hx_confidential: gyaneData.is_sexual_hx_confidential,
      test_done:gyaneData.test_done,
      any_previous_complication: gyaneData.any_previous_complication,
      lactating:gyaneData.lactating,
      risk_factors:gyaneData.risk_factors,
      current_pregnancy_remarks:gyaneData.current_pregnancy_remarks,
      mother_remarks:gyaneData.mother_remarks,
      sexual_remarks:gyaneData.sexual_remarks,
      gravidity:gyaneData.gravidity,
      parity:gyaneData.parity,
      ectopic:gyaneData.ectopic,
      abortion:gyaneData.abortion,
      live_birth:gyaneData.live_birth,
      gestational_age_lmp_weeks:gyaneData.gestational_age_lmp_weeks,
      gestational_age_lmp_days:gyaneData.gestational_age_lmp_days,
      gestational_age_usg_weeks:gyaneData.gestational_age_usg_weeks,
      gestational_age_usg_days:gyaneData.gestational_age_usg_days,
      remarks:gyaneData.remarks,
      no_of_fetus:multipleFetalParamsData?.length,
      multipleFetalParams: multipleFetalParamsData,
      physicalExamData: physicalExamData,
      is_draft: "0",
    };

    // axios.post(PATIENT_CHECKOUT, {
    //   booking_id: patient.qms_token_id
    // });

    axios.post(COMPLETE_APPOINTEMENT, qs.stringify(params)).then((res) => {
      if (this.state?.details?.qms_details?.source == "qms") {
        let params = { status: 'complete', token_id: this.state?.details?.qms_details?.token_id, hospital_id: hospital_id }
        axios.post(UPDATE_QMS_STATUS, qs.stringify(params));
      }

      this.setState({
        isPrintLoader: false,
      });

      const browser = Bowser.parse(window.navigator.userAgent).browser.name;
      if (browser == "Safari") {
        this.props.history.push({
          pathname: `/patient-queue/${hospital_id}`,
          state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
        });
      } else {
        this.props.history.push({
          pathname: `/patient-queue/${hospital_id}?reference=${patient.qms_token_id}`,
          state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
        });
      }

      // this.props.history.push({
      //   pathname: `/patient-queue/${hospital_id}`,
      //   state: { activeTab: TAB_NAMES[1] }, // 1 is completed TAB
      // });
    });
  };
  handleCompleteAppointmentDraft = async () => {
    const { patient, hospital_id, isPracticing, reviewNotes, reviewTxt, surgeryRequestRequired, surgeryRequired, gyaneData, physicalExamData,multipleFetalParamsData, admissionChecked, case_type} = this.state;

    if (this.state.admission_advice_validation == 'true') {
      if (admissionChecked == true) {
        if (case_type == null || case_type == 0) {
          this.setState({
            case_type_error: true,
          });
          notification.error({
            message: 'Case type  is mandatory, Please select as applicable.',
            placement: 'topRight'
          });
          return false;
        }
      }
      if (surgeryRequired && surgeryRequestRequired) {
        notification.error({
          message: 'Kindly add atleast one surgery procedure or surgery request for the admission.',
          placement: 'topRight'
        });
        return false;
      }
    }
    else
    {
      if (surgeryRequestRequired) {
        notification.error({
          message: 'Kindly add planned surgery details for the admission.',
          placement: 'topRight'
        });
        return false;
      }
    }

    this.setState({
      isPrintDraftLoader: true,
    });

    await this.vitalAndKfComponentRef?.current?.storeVitalDetails();
    await this.chiefComponentRef?.current?.handleSubjectiveFormSubmit("Print&Complete");
    await this.saveCustomSubjectiveOnPrint();
    await this.saveCustomParentSubjectiveOnPrint();
    await this.clinicalNotesRef?.current?.handleSubjectiveFormSubmit();
    await this.internalNotesRef?.current?.handleSubjectiveFormSubmit();

    if (!this.state.allowEdit) {
      this.props.history.push({
        pathname: `/patient-queue/${hospital_id}`,
      });
      return;
    }

    if (!isPracticing) {
      this.handleModalPopup(null, "isPracticingModal");
      return;
    }

    var ipd_slots = LS_SERVICE.get("ipd_slots")
    var commonfacilityName = ipd_slots.find(item => item?.hospitalId == LS_SERVICE.get("FACILITY_ID"));

    const params = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: patient.qms_token_id,
      patient_id: patient.patient_id,
      doctor_id: patient.doctor_id,
      hospital_id,
      print_pdf: 1,
      type: 'SS',
      store_id: LS_SERVICE.get("STORE_ID"),
      facility_id: commonfacilityName?.facilityId || LS_SERVICE.get("FACILITY_ID"),
      facility_name: commonfacilityName?.facilityName || LS_SERVICE.get("FACILITY_NAME"),
      enterprise_id: LS_SERVICE.get("ENTERPRISE_ID"),
      enterprise_name: LS_SERVICE.get("ENTERPRISE_NAME"),
      USER_NAME: LS_SERVICE.get('staff_name'),
      // speciality_id: LS_SERVICE.get("speciality_id"),
      speciality_id: LS_SERVICE.get('call_patient_detail').speciality_id || null,
      gender: LS_SERVICE.get('call_patient_detail').gender || null,
      fromfacility: commonfacilityName?.hospitalName || LS_SERVICE.get("FACILITY_NAME"),
      fromfacilityid: commonfacilityName?.hospitalId || LS_SERVICE.get("FACILITY_ID"),
      //review_notes: reviewNotes == '' ? reviewTxt : reviewNotes
      review_notes: reviewTxt,
      cycle_length:gyaneData.cycle_length,
      lmp:gyaneData.lmp == "Invalid date" ? null : gyaneData.lmp,
      edd_by_lmp:gyaneData.edd_by_lmp == "Invalid date" ? null : gyaneData.edd_by_lmp,
      corrected_edd:gyaneData.corrected_edd == "Invalid date" ? null : gyaneData.corrected_edd,
      pregnant:gyaneData.pregnant,
      is_checked_aph: gyaneData.is_checked_aph,
      is_confidential: gyaneData.is_confidential ,
      mother_fetal_printwithprescription: gyaneData.mother_fetal_printwithprescription,
      is_sexual_hx_confidential: gyaneData.is_sexual_hx_confidential,
      test_done:gyaneData.test_done,
      any_previous_complication: gyaneData.any_previous_complication,
      lactating:gyaneData.lactating,
      risk_factors:gyaneData.risk_factors,
      current_pregnancy_remarks:gyaneData.current_pregnancy_remarks,
      mother_remarks:gyaneData.mother_remarks,
      sexual_remarks:gyaneData.sexual_remarks,
      gravidity:gyaneData.gravidity,
      parity:gyaneData.parity,
      ectopic:gyaneData.ectopic,
      abortion:gyaneData.abortion,
      live_birth:gyaneData.live_birth,
      gestational_age_lmp_weeks:gyaneData.gestational_age_lmp_weeks,
      gestational_age_lmp_days:gyaneData.gestational_age_lmp_days,
      gestational_age_usg_weeks:gyaneData.gestational_age_usg_weeks,
      gestational_age_usg_days:gyaneData.gestational_age_usg_days,
      remarks:gyaneData.remarks,
      no_of_fetus:multipleFetalParamsData?.length,
      multipleFetalParams: multipleFetalParamsData,
      physicalExamData: physicalExamData,
      is_draft: "1",
    };


    const response = await axios.post(COMPLETE_APPOINTEMENT_DRAFT, qs.stringify(params));

    this.setState({
      isPrintDraftLoader: false,
    });

    let data = response.data;
    if (response && response.status === 200) {
      if(data.status){
        this.setState({ showRxModal: true, rxPdfPath: data.pdf, rxPdfPrint: true })
      }else{
        notification.error({
          message: data.message,
          placement: 'topRight'
        })
      }
    }else{
      notification.error({
        message: data.message,
        placement: 'topRight'
      })
    }

  };

  onbooknow = (submit = false) => {
    const { followUpRadio, patient, follow_up_Others, otherVal, hospital_id } = this.state;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    let date = new Date();
    let todayDate = date.getDate();
    let foramttedDate = '';
    if (followUpRadio !== -1) {
      let newDate = date.setDate(todayDate + parseInt(followUpRadio));
      foramttedDate = new Date(newDate).toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-');
    } else {
      date = moment().add(+otherVal, follow_up_Others);
      foramttedDate = new Date(date._d).toLocaleDateString("zh-Hans-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//gm, '-');
      if (!submit) this.handleFollowUpApi();
    }
    patient.id = patient.patient_id;

    if (submit) {
      LS_SERVICE.set('bookNowPatient', patient);
      this.props.history.push({
        pathname: `/patient/todays-visit/follow-up/${hospital_id}/${patient.patient_id}/${REFERENCE_TYPE}/${patient.qms_token_id}/${foramttedDate}`,
        state: { patient }
      })
    }
  }

  physiotherapySave = _ => {
    this.setState({ disableConfirmButton: true });
    const { patient, physioText, physiotherapy_advised } = this.state;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    const PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: REFERENCE_TYPE,
      physiotherapy_advised: physiotherapy_advised === 2 ? 0 : 1,
      physiotherapy_comment: physioText
    }
    if (physiotherapy_advised === 2) {
      this.setState({
        physioText: ""
      })
      PARAMS.physiotherapy_comment = "";
    }
    axios.post(PHYSIO_UPADTE, qs.stringify(PARAMS))
      .then(res => {
        this.setState({ disableConfirmButton: false });
      })
  }

  handleReferralDetails = () => {
    this.setState({
      isReferralModal: true
    });
  }
  reviewNote = async (e) => {
    const { name, value } = e.target
    await this.setState({
      // reviewNotes: value,
      reviewTxt: value
    })
    if (value != '') {
      e.target.parentElement.classList.add('hasdata');
    } else {
      e.target.parentElement.classList.remove('hasdata');
    }
  }

  printCompleteButtonDiabled(medicineDataEmpty) {
    this.setState({ medicineDataEmpty })
  }

  async saveNotesModel(e, model_type) {
    await this.setState({ notesModelActive: true })
    e.preventDefault();
    let { name, value } = e.target;
    let input_type = '';
    let content = value;
    if (name == 'custom_field_1') { input_type = 'cc1'; }
    if (name == 'custom_field_2') { input_type = 'cc2'; }
    if (name == 'custom_field_3') { input_type = 'cc3'; }
    if (name == 'custom_field_4') { input_type = 'cc4'; }
    if (name == 'custom_field_5') { input_type = 'cc5'; }
    doctorNotesService.init({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    });

    this.setState({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    })
  }

  saveTextBoxData(data) {
    let input_type = data[0].type;
    let arr = ['pc1','pc2','pc3','pc4','pc4','pc5'];
    if(arr.includes(input_type)){
      this.saveCustomSubjectiveParentFromDoctor(data, 'N');
    }else{
      this.saveCustomSubjectiveFromDoctor(data, 'N');
    }    
  }

  // saveCustomSubjectiveFromDoctor(data, defalut_case_type) {
  //   const { patient, subjective_custom } = this.state;
  //   let value = data.map(obj => obj.content).join("\n");
  //   let name = '';
  //   let newString = '';
  //   if (data[0].type == 'cc1') {
  //     newString = subjective_custom.custom1 != '' ? subjective_custom.custom1 + "\n" + value : value;
  //     name = 'custom_field_1';
  //     subjective_custom.custom1 = defalut_case_type == 'Y' ? data[0].content : newString
  //   }
  //   if (data[0].type == 'cc2') {
  //     newString = subjective_custom.custom2 != '' ? subjective_custom.custom2 + "\n" + value : value;
  //     name = 'custom_field_2';
  //     subjective_custom.custom2 = defalut_case_type == 'Y' ? data[0].content : newString;
  //   }
  //   if (data[0].type == 'cc3') {
  //     newString = subjective_custom.custom3 != '' ? subjective_custom.custom3 + "\n" + value : value;
  //     name = 'custom_field_3';
  //     subjective_custom.custom3 = defalut_case_type == 'Y' ? data[0].content : newString;
  //   }
  //   if (data[0].type == 'cc4') {
  //     newString = subjective_custom.custom4 != '' ? subjective_custom.custom4 + "\n" + value : value;
  //     name = 'custom_field_4';
  //     subjective_custom.custom4 = defalut_case_type == 'Y' ? data[0].content : newString;
  //   }
  //   if (data[0].type == 'cc5') {
  //     newString = subjective_custom.custom5 != '' ? subjective_custom.custom5 + "\n" + value : value;
  //     name = 'custom_field_5';
  //     subjective_custom.custom5 = defalut_case_type == 'Y' ? data[0].content : newString;
  //   }

  //   this.setState({
  //     subjective_custom: subjective_custom
  //   });
  //   var PARAMS = {
  //     reference_id: patient.qms_token_id,
  //     reference_type: REFERENCE_TYPE,
  //     patient_id: patient.patient_id,
  //     doctor_id: LS_SERVICE.get('staff_id'),
  //     [name]: newString
  //   }
  //   axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS))
  //   this.setState({ notesModelActive: false })
  // }

  saveCustomSubjectiveParentFromDoctor(data, defalut_case_type) {
    const { patient, parent_customdata } = this.state;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    let value = data.map(obj => obj.content).join("\n");
    let name = '';
    let newString = '';
    let input_type = {
      pc1: 'custom_field_parent_1',
      pc2: 'custom_field_parent_2',
      pc3: 'custom_field_parent_3',
      pc4: 'custom_field_parent_4',
      pc5: 'custom_field_parent_5',
    }

    let obj ={
      custom_field_parent_1 : 'parent_custom1',
      custom_field_parent_2 : 'parent_custom2',
      custom_field_parent_3 : 'parent_custom3',
      custom_field_parent_4 : 'parent_custom4',
      custom_field_parent_5 : 'parent_custom5',
    }
    
    let type = data[0].type;
    name = input_type[type];
    let field_type = obj[name];
    if( defalut_case_type == 'Y' && parent_customdata[field_type] != "" && parent_customdata[field_type] != null){
      return;
     }
    newString = parent_customdata[field_type] !='' && parent_customdata[field_type] != null && parent_customdata[field_type] != undefined ? parent_customdata[field_type] + "\n" + value : value;
    parent_customdata[field_type] = defalut_case_type == 'Y' ?   parent_customdata[field_type] == "" || parent_customdata[field_type] == null ? data[0].content : parent_customdata[field_type] : newString ;
    
    this.setState({
      parent_customdata: parent_customdata
    });
    var PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: REFERENCE_TYPE,
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      [name]: newString
    }
    axios.post(PARENT_CUSTOM_STORE, qs.stringify(PARAMS))
    this.setState({ notesModelActive: false })
  }


  saveCustomSubjectiveFromDoctor(data, defalut_case_type) {
    const { patient, subjective_custom } = this.state;
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    let value = data.map(obj => obj.content).join("\n");
    let name = '';
    let newString = '';
    let input_type = {
      cc1: 'custom_field_1',
      cc2: 'custom_field_2',
      cc3: 'custom_field_3',
      cc4: 'custom_field_4',
      cc5: 'custom_field_5',
    }

    let obj ={
      custom_field_1 : 'custom1',
      custom_field_2 : 'custom2',
      custom_field_3 : 'custom3',
      custom_field_4 : 'custom4',
      custom_field_5 : 'custom5',
    }
    
    let type = data[0].type;
    name = input_type[type];
    let field_type = obj[name];
    if( defalut_case_type == 'Y' && subjective_custom[field_type] != "" && subjective_custom[field_type] != null){
      return;
    }

    newString = subjective_custom[field_type] !='' && subjective_custom[field_type] != null && subjective_custom[field_type] != undefined ? subjective_custom[field_type] + "\n" + value : value;
    subjective_custom[field_type]= defalut_case_type == 'Y' ? subjective_custom[field_type] == "" || subjective_custom[field_type] == null ? data[0].content :subjective_custom[field_type] : newString ;


    this.setState({
      subjective_custom: subjective_custom
    });
    var PARAMS = {
      reference_id: patient.qms_token_id,
      reference_type: REFERENCE_TYPE,
      patient_id: patient.patient_id,
      doctor_id: LS_SERVICE.get('staff_id'),
      [name]: newString
    }
    axios.post(SUBJECTIVE_CUSTOM_STORE, qs.stringify(PARAMS))
    this.setState({ notesModelActive: false })
  }

  resetModel() {
    // this.setState({notesModel:false})
    // this.handelDefaultText();
  }

  resetNotesModel() {
    this.setState({ notesModelActive: false })
  }

  async saveNotesForParentModel(e, model_type) {
    await this.setState({ notesModelActive: true })
    e.preventDefault();
    let { name, value } = e.target;
    let input_type = '';
    let content = value;
    if (name == 'custom_field_parent_1') { input_type = 'pc1'; }
    if (name == 'custom_field_parent_2') { input_type = 'pc2'; }
    if (name == 'custom_field_parent_3') { input_type = 'pc3'; }
    if (name == 'custom_field_parent_4') { input_type = 'pc4'; }
    if (name == 'custom_field_parent_5') { input_type = 'pc5'; }
    doctorNotesService.init({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    });

    this.setState({
      content: content,
      // notesModel: true,
      input_type: input_type,
      model_type: model_type
    })
  }

  async handelDefaultText() {
    let doctor_id = LS_SERVICE.get('staff_id');
    const { consult_type, ss_configuration} = this.state;

    let parent_obj ={
      pc1 :11,
      pc2 :12,
      pc3 :13,
      pc4 :14,
      pc5 :15,
    }

    let child_obj ={
      cc1 :11,
      cc2 :12,
      cc3 :13,
      cc4 :14,
      cc5 :15,
    }
    
    const res = await axios.get(GET_DEFAULT_TEXT({ doctor_id: doctor_id }));
    if (res.data.data.length > 0) {
      let data = res.data.data;
      this.setState({default_text:data});
      let arr = ['cc1', 'cc2', 'cc3', 'cc4', 'cc5','pc1' ,'pc2','pc3','pc4','pc5'];
      let parent_arr = ['pc1' ,'pc2','pc3','pc4','pc5'];
      let new_list = data.filter(obj => arr.includes(obj.type));
      new_list.map((data ,i) => {
        let arr = [];
        arr.push(data);
        if(parent_arr.includes(data.type)){
          if(ss_configuration.some(o => (o?.id === parent_obj[data.type] && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))){
            this.saveCustomSubjectiveParentFromDoctor(arr, 'Y');
          }         
        }else{
           if(ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === child_obj[data.type]  && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
           {
            this.saveCustomSubjectiveFromDoctor(arr, 'Y');
           }
        }
      })
    }

  }
  calculateAssessmentLength(data) {
    if (data && data.length > 0) {
      this.setState({ assessmentDataLength: data.length });
    } else {
      this.setState({ assessmentDataLength: 0 });
    }
  }

  showCP() {
    let flag = false;
    const { assessmentDataLength, medicineLength, testLength } = this.state;
    if (assessmentDataLength > 0 && (medicineLength > 0 || testLength > 0)) {
      flag = true;
    }
    return flag;
  }


  isPrintButtonDisabled(){
    let buttonDisabled = true
    if(this.state.isEMRButtonDisabled && !this.state.isEditRxTrue && this.state.isEmrConfigAdmissionAdvised){
      if((this.state.disableCompleteButton || this.state.radioChecked || this.state.medicineDataEmpty || this.state.junior_doctor_complete_appointment || moment(LS_SERVICE.get('call_patient_detail').booking_date).format('YYYY-MM-DD')>moment().format('YYYY-MM-DD'))){
        buttonDisabled = true
      }else{
        buttonDisabled = false
      }
    }else{
      if((this.state.disableCompleteButton || this.state.medicineDataEmpty || this.state.junior_doctor_complete_appointment || moment(LS_SERVICE.get('call_patient_detail').booking_date).format('YYYY-MM-DD')>moment().format('YYYY-MM-DD'))){
        buttonDisabled = true
      }else{
        buttonDisabled = false
      }
    }

    return buttonDisabled;
   
  }

  updateVitalsData = (data) => {
    this.setState({vitalsDataFromVitals: data})
  }
  disabledDate(current) {
    return current < moment().startOf('day');
  }

  async getReferralConfiguration() {
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    let response = await Axios.get(GET_REFERRAL_CONFIGURATION({ staff_id, hospital_id }));
    if (response && response.status == 200) {
      let data = response.data.data;
      this.setState({ isReferral: data.referral_print == "Y" ? true : false })
    }
  }

  onChangeReferredBy = (e) => {
    const { value } = e.target;
    this.setState({ 
      // referred_by: value,
      referralName: value, 
      isButtonDisabled: value.trim() === '', });
  };

  referredByOnSubmit = () => {
    var params = {
      reference_id: LS_SERVICE.get('reference_id'),
      reference_type: LS_SERVICE.get('reference_type'),
      patient_id: LS_SERVICE.get('call_patient_id'),
      staff_id: LS_SERVICE.get('staff_id'),
      referred_by: this.state.referralName
    };
    Axios
      .post(STORE_REFERRED_DETAILS, qs.stringify(params))
      .then((success) => {
        if (success.data.status) {
          notification.success({
            message: success.data.message,
            placement: "topRight",
          });
          this.getAllReferrealData()
          this.setState({ addReferralSourceModal: false });
        } else {
          notification.error({
            message: success.data.message,
            placement: "topRight",
          });
        }
      })
      .catch((err) =>
        console.log(err));
  }

  getAllReferrealData = () => {
    this.setState({ loader: true });
    const params = {
      "reference_id": LS_SERVICE.get('reference_id')
    }

    Axios.get(GET_ALL_REFERRED_DETAILS(params))
      .then(response => {
        if (response) {
          let apiResponse = response.data.referredDetails || [];
          let lastObj = apiResponse && apiResponse.slice(-1)[0];
          this.setState({ referralData: apiResponse, referred_by: lastObj.referredBy, referralName:lastObj.referredBy  });
        }
      })
      .catch(error => {
        // notification.error({
        // message: 'Error occurred while fetching referral data',
        // placement: 'topRight',
        // duration: this.state.toast_duration,
        // });
      })
    this.setState({ loader: false });
  }

  getReferralDetailsFromHis = async () => {
    const params = {
      reference_id: LS_SERVICE.get('reference_id'),
      staff_id: LS_SERVICE.get('staff_id'),
      hospital_id: LS_SERVICE.get('slot_hospital_id'),
      encounter_id: LS_SERVICE.get('PATIENT_ENCOUNTER_ID'),
      enterprise_id: LS_SERVICE.get('ENTERPRISE_ID'),
      facility_id: LS_SERVICE.get('FACILITY_ID'),
    };

    try {

      let response = await Axios.post(GET_REFERRED_DETAILS_FROM_HIS, params);
      if (response && response.status === 200) {
        let data = response?.data?.data;
        this.setState({ referralByFromHIS: data?.referredBy || '' });
      }
    } catch (error) {
      console.error('Error fetching referral details:', error);
    }
  }

  loadDiagnosisData = async () => {
    const { patient } = this.state;
    const { qms_token_id } = patient;

    return await axios.get(ASSESSMENT_API({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id }))
      .then(success => {
        this.setState({
          getDiagnosisList: success?.data?.data?.details
        })
      })
      .catch(error => {
        console.error("Error loading diagnosis data:", error);
      });
  }
  sendTestOrder = async() => {
    const params = {
      reference_id: LS_SERVICE.get('reference_id'),
      reference_type: LS_SERVICE.get('reference_type'),
      type: 'SS',
      enterprise_id: LS_SERVICE.get('ENTERPRISE_ID'),
      facility_id: LS_SERVICE.get('FACILITY_ID'),
    };

    let response = await Axios.post(SEND_TEST_ORDERS, qs.stringify(params));
    if (response.data.status) {
      notification.success({
        message: response.data.message,
        placement: "topRight",
      });
    }
  }

  renderPdfViewer = () => {
    const { rxPdfPath, rxPdfPrint } = this.state;
  
    if (!rxPdfPath) return null;
  
    return (
      <iframe
        id="pdfDraftViewer"
        src={rxPdfPath}
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="700"
        style={{ overflow: 'hidden' }}
        // onLoad={this.handlePdfLoad}
      >
        {LOADER_RED}
      </iframe>
    );
  };

  handlePdfLoad = () => {
    const { rxPdfPrint } = this.state;
    const pdfIframe = document.getElementById('pdfDraftViewer');
    const browser = Bowser.parse(window.navigator.userAgent).browser.name;
  
    if (rxPdfPrint) {
      if (browser === "Safari") {
        setTimeout(() => pdfIframe.contentWindow.print(), 2000);
      } else {
        pdfIframe.contentWindow.print();
      }
    }
  };
  render() {
    
    if (this.state.instructionList?.length > 0) {
      if (this.instructionCollapseRef?.current?.classList.contains("collapsed")) {
        this.instructionCollapseRef?.current?.click();
      }
    }
    const myObject = this.state.gyaneData;
    var keysArray = Object?.values(myObject);
    var lengthUsingKeys = keysArray?.length;
    var hasValues = keysArray.some(value => keysArray[value] !== null && keysArray[value] !== undefined);

    if (lengthUsingKeys > 0 && hasValues) {
      if (this.obsGynaeCollapseRef?.current?.classList.contains("collapsed")) {
        this.obsGynaeCollapseRef?.current?.click();
      }
    }
    const { patient, ss_configuration, consult_type, details, field_status, dataLoading, physiotherapy_advised, physioText, admissionChecked, admissionComment, icu_days, ward_days, case_type, surgeryButtonEnabled, surgeryEnabled, followUpRadio, isAdmissionAdvisedPriority, favourite, intuitive, constants, active_tab, patient_id, reference_id, hospital_id, referralDetailsArr, allowEdit, patient_note_doctor, patient_docments, medicineDataEmpty, isEmrConfigAdmissionAdvised, addedDrugsList,obsGyaneData, case_type_error, admission_advice_validation, isReferral, referralByFromHIS, sendTestOrder} = this.state;
    const isObsGynaePatient = LS_SERVICE.get('isObsGynaePatient') ? LS_SERVICE.get('isObsGynaePatient') : false;
    const call_patient_detail = LS_SERVICE.get('call_patient_detail');

    var config = LS_SERVICE.get('emr_server_config');
    var app_env = config?.app_env;
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
    const refferaltext =
      <>
        {referralDetailsArr?.length > 0 ? (
          <>
            <div>REFERRAL DATE & TIME</div>
            <div>{referralDetailsArr[0].referral_date_time}</div>
            <div className='mt-3'>REFERRAL DOCTOR</div>
            <div>Dr {referralDetailsArr[0].referral_by}</div>
            <div className='mt-3'>DOCTOR'S NOTES</div>
            <div>{referralDetailsArr[0].referral_note}</div>
          </>
        ) : null}
      </>
    var custom1 = {};
    var custom2 = {};
    var custom3 = {};
    var custom4 = {};
    var custom5 = {};
    var parent_custom1 = {};
    var parent_custom2 = {};
    var parent_custom3 = {};
    var parent_custom4 = {};
    var parent_custom5 = {};
    var field_status_patient_history = []

    // VARIABLE NAME MUST MATCH THE NAME IN THE DB
    var parent_variable = {
      vital_and_kf: null,
      patient_hx: null,
      subjective: null,
      diagnosis: null,
      medicine: null,
      test: null,
      instruction: null,
      supporting_material: null,
      referral: null,
      other: null,
      review: null,
      custom_field_parent_1: null,
      custom_field_parent_2: null,
      custom_field_parent_3: null,
      custom_field_parent_4: null,
      custom_field_parent_5: null,
    };
    var child_variable = {
      custom_field_1: null,
      custom_field_2: null,
      custom_field_3: null,
      custom_field_4: null,
      custom_field_5: null,
      clinical_notes: null,
      internal_notes: null,
      physiotherapy: null,
      admission: null,
      followup: null
    }

    if (ss_configuration) {
      var custom_parent = ss_configuration.find(o => o?.id === 3)
      if (custom_parent && custom_parent.sub_items) {
        custom1 = custom_parent.sub_items.find(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom2 = custom_parent.sub_items.find(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom3 = custom_parent.sub_items.find(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom4 = custom_parent.sub_items.find(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
        custom5 = custom_parent.sub_items.find(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))
      }
      if (ss_configuration.some(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom1 = ss_configuration.find(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom2 = ss_configuration.find(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom3 = ss_configuration.find(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom4 = ss_configuration.find(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (ss_configuration.some(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))) {
        parent_custom5 = ss_configuration.find(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
      }
      if (field_status?.patient_history?.length > 0) {
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 4 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "allergies"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 5 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "existing_conditions"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 6 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "surgical_hx"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 7 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "social_hx"))
        }
        if (ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 8 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) {
          field_status_patient_history.push(field_status?.patient_history?.find(o => o.type === "family_hx"))
        }
        var child_others = ss_configuration.find(o => o?.id === 10).sub_items;
        child_variable.physiotherapy = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 18 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row mb-2'>
                <div className='col-6 d-flex align-items-center'>
                  <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">PHYSIOTHERAPY ADVISED </label>
                  <div className="mr-2 d-md-inline">
                    <Radio.Group disabled={!this.state.allowEdit} onChange={e => this.handleRadioChange(e, 'physiotherapySave')} name='physiotherapy_advised' value={physiotherapy_advised}>
                      <Radio value={1} name="physiotherepy_radio">Yes</Radio>
                      <Radio value={2} name="physiotherepy_radio">No</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={`col-6 mt-2 ${physiotherapy_advised == 2 ? "d-none" : ""}`} id="therapy_text">
                  <div className={`form-group mb-0 ${physioText != '' ? 'hasdata' : ''}`}>
                    <textarea disabled={!this.state.allowEdit} onInput={(e) => this.autoHeight(e)} rows={1} id="comment" className="form-control autoHeightText"
                      placeholder="Therapy Required" value={physioText} onBlur={this.physiotherapySave}
                      onChange={(e) => this.setState({ physioText: e.target.value })} />
                    <label htmlFor="comment">Therapy Required</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.admission = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 19 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row mb-2 mt-2'>
                <div className='col-4 d-flex align-items-center'>
                  <label className="d-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">ADMISSION ADVISED <span style={{color: "red"}}>*</span> </label>
                  <div className="mr-2 d-md-inline">
                    <Radio.Group onChange={e => this.handleRadioChange(e, 'admissionSave')} name='admissionChecked' value={this.state.isEditRxTrue!=null && this.state.isEditRxTrue ? admissionChecked : isEmrConfigAdmissionAdvised ? {} :admissionChecked}>
                      <Radio value={true} name="admission_radio">Yes</Radio>
                      <Radio value={false} name="admission_radio">No</Radio>
                    </Radio.Group>
                  </div>
                </div>
                {admissionChecked ? (
                  <>
                    <div className='col-2 d-flex align-self-center'>
                      <span className='ant_switch_button_sm phsio_advised' id="emer_elect">
                        <BootstrapSwitchButton
                          width={150} height={24}
                          size="xs"
                          checked={isAdmissionAdvisedPriority}
                          offlabel='Elective'
                          onlabel='Emergency'
                          offstyle="success"
                          onstyle="alert"
                          onChange={this.handleToggleAdmission}
                        />
                      </span>
                    </div>
                    <div className={`col-6 ${admissionChecked == false ? "d-none" : ""}`} id="therapy_text">
                      <div className={`form-group mb-0 ${admissionComment != '' ? 'hasdata' : ''}`}>
                        <textarea onInput={(e) => this.autoHeight(e)} rows={1} id="admission_comment" className="form-control autoHeightText"
                          placeholder="Therapy Required" value={admissionComment} onBlur={this.handleAdmissionAdvicedComment}
                          onChange={(e) => this.setState({ admissionComment: e.target.value })} />
                        <label htmlFor="admission_comment">Admission Advised Remark For Patient</label>
                      </div>

                    </div>
                  </>
                ) : ""}
              </div>
              :
              null
          }

          {admissionChecked ? (
            <>
              <div className='row my-2'>
                <div className='col-2'>
                  <div className={`form-group pl-0 ${ward_days != '' ? 'hasdata' : ''}`}>
                    <input type="text" id="ward_days" name="ward_days" value={ward_days} disabled={!this.state.allowEdit || this.state.admissionAdvisedDisabled} onChange={this.handleOnTextChange} onBlur={this.handleOnTextChange} className="form-control onlynumbers" placeholder="Ward Days" />
                    <label htmlFor="ward_days">Ward Days</label>
                  </div>
                </div>
                <div className='col-2'>
                  <div className={`form-group pl-0 ${icu_days != '' ? 'hasdata' : ''}`}>
                    <input type="text" id="icu_days" name="icu_days" value={icu_days} disabled={!this.state.allowEdit || this.state.admissionAdvisedDisabled} onChange={this.handleOnTextChange} onBlur={this.handleOnTextChange} className="form-control onlynumbers" placeholder="ICU Days" />
                    <label htmlFor="icu_days">ICU Days</label>
                  </div>
                </div>
                <div className='col-3'>
                  <div className={`form-group pt-1 ${case_type > 0 ? 'hasdata' : ''}`}>
                    <select style={{ backgroundColor: 'none !important', border: case_type_error ? '1px solid red' : '' }} className="form-control mandatory-field case_type_select" value={this.state.case_type}
                      name="case_type" disabled={!this.state.allowEdit || this.state.admissionAdvisedDisabled} onChange={this.handleCaseType}>
                      <option value={0}></option>
                      {this.state.caseTypeList !== undefined && this.state.caseTypeList.length > 0 ? this.state.caseTypeList.map((d, i) => (
                            <option value={d.id}>{d.description}</option>
                          )) : null}
                    </select>
                    <label htmlFor="case_type">Case Type {admission_advice_validation == "true" ? <span style={{color: "red"}}>*</span> : ''}</label>
                  </div>
                </div>
                <div className={`col text-right pr-5 ${admissionChecked == false ? "d-none" : ""}`} >
                <a className={`btn btn-outline-primary small px-2 mr-2 ${surgeryEnabled == false ? "d-none" : ""}`} id="surger_req" onClick={(e) => this.handleSurgery()}>+ SURGERY</a>
                  <a className={`btn btn-outline-primary small px-2 mr-2 ${surgeryButtonEnabled == false ? "d-none" : ""}`} id="surger_req" onClick={(e) => this.handleSurgeryRequest()}>+ SURGERY REQUEST</a>
                  <a className="btn btn-outline-primary small px-2 ml-2" id="admint_ord" onClick={(e) => this.handleAdmitOrder()}>+ INTERNAL REMARKS</a>
                </div>
              </div>
            </>
          ) : ""}
        </>
        child_variable.followup = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 20 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className="row mb-2 mt-2" id="followup">
                <div className="col-12 col-xl">
                  <label className="d-md-inline font-weight-semibold text-dark mr-3 size-h6 mb-0">FOLLOW UP (NUMBER OF DAYS)</label>
                  <div className="mr-2 d-md-inline">
                    <Radio.Group onChange={this.handleFollowUpRadio} checked={followUpRadio} value={followUpRadio} disabled={!this.state.allowEdit}>
                      <Radio value={3} name="followUpRadio" title={'0'}>3</Radio>
                      <Radio value={5} name="followUpRadio" title={'0'}>5</Radio>
                      <Radio value={7} name="followUpRadio" title={'0'}>7</Radio>
                      <Radio value={15} name="followUpRadio" title={'0'}>15</Radio>
                      <Radio value={30} name="followUpRadio" title={'0'}>30</Radio>
                      <Radio value={60} name="followUpRadio" title={'0'}>60</Radio>
                      <Radio value={-1} name="followUpRadio" title={'-1'}>Other</Radio>
                    </Radio.Group>
                  </div>
                
                  <div className="d-md-inline inline-input-other">
                    <div className="form-label-group input-group custom-addon" id="followup_other_block" style={{ display: this.state.followUpRadio === -1 ? "flex" : "none" }}>
                      <input type="text" id="followup_other_value" className="form-control " name="otherVal" maxLength="3"
                        value={this.state.otherVal} onChange={this.handleOnChange} onBlur={() => this.onbooknow()} disabled={!this.state.allowEdit} />
                      <span className="input-group-addon custom-size-addon bg-white">
                        <select className="custom-select" value={this.state.follow_up_Others} select={this.state.follow_up_Others}
                          name="follow_up_Others" onChange={e => this.handleOnChange(e, () => this.onbooknow())} disabled={!this.state.allowEdit}>
                          <option value={'days'}>Days</option>
                          <option value={'weeks'}>Weeks</option>
                          <option value={'months'}>Months</option>
                          <option value={'years'}>Years</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  {/* <p>followUpRadio:{this.state.followUpRadio}</p>
                  <p>follow_up_days:{this.state.follow_up_days}</p> */}
                  <div className="col-12 col-xl d-md-inline">
                    {this.state.followUpRadio!=null||this.state.otherVal!=null? 
                      <DatePicker
                        defaultValue={this.state.follow_up_date}
                        value={this.state.follow_up_date}
                        disabledDate={this.disabledDate}
                        format='DD MMM, YYYY (dddd)'
                        allowClear={false}
                        onChange={(dateDays, dateString)=>{
                          this.followupDatePicker(dateDays, dateString)
                        }}
                      />
                    :
                      null
                    }
                   
                  </div>
                </div>
                {
                  ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                ?
                  null
                :
                  <div className="col-12 col-xl-auto pr-5">
                    <button type="button" className="float-right btn btn-outline-secondary text-uppercase small px-2"
                      disabled={!this.state.allowEdit ? true : this.state.bookNowAllow}
                      onClick={() => this.onbooknow(true)} >BOOK NOW</button>
                  </div>
                }
              </div>
              :
              null
          }

        </>

        var child_subjective = ss_configuration.find(o => o?.id === 3).sub_items;
        child_variable.custom_field_1 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom1 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom1.name }) }}
                      className={(this.state.speech.identifier === custom1.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom1} name={custom1.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom1} name={custom1.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom1?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={custom1.name}
                      name={custom1.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom1}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom1.name}>{custom1?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_2 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom2 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom2.name }) }}
                      className={(this.state.speech.identifier === custom2.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom2} name={custom2.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom2} name={custom2.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom2?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={custom2.name}
                      name={custom2.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom2}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom2.name}>{custom2?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_3 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom3 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom3.name }) }}
                      className={(this.state.speech.identifier === custom3.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom3} name={custom3.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom3} name={custom3.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom3?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={custom3.name}
                      name={custom3.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom3}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom3.name}>{custom3?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_4 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom4 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom4.name }) }}
                      className={(this.state.speech.identifier === custom4.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom4} name={custom4.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom4} name={custom4.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom4?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={custom4.name}
                      name={custom4.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom4}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom4.name}>{custom4?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>
        child_variable.custom_field_5 = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12'>
                  <div className={this.state?.subjective_custom?.custom5 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: custom5.name }) }}
                      className={(this.state.speech.identifier === custom5.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.subjective_custom?.custom5} name={custom5.name} onClick={(e) => this.saveNotesModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.subjective_custom?.custom5} name={custom5.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>
                    <textarea
                      placeholder={custom5?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={custom5.name}
                      name={custom5.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.subjective_custom?.custom5}
                      onChange={this.saveCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={custom5.name}>{custom5?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }

        </>
        child_variable.clinical_notes = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 16 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <ClinicalNotes ref={this.clinicalNotesRef} isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange}  micSpeech={this.state.speech} onMicClick={(getData) => this.initSpeech(getData)} />
              :
              null
          }
        </>
        child_variable.internal_notes = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 17 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <InternalNotes ref={this.internalNotesRef} isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} micSpeech={this.state.speech} onMicClick={(getData) => this.initSpeech(getData)}/>
              :
              null
          }
        </>

        parent_variable.vital_and_kf = <>
          {
            ss_configuration.some(o => (o?.id === 1 && o.sub_items.some(p => ((p?.id === 1 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 2 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <>
                {/* {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?  */}
                <VitalsKeyFindings ref={this.vitalAndKfComponentRef} sourcescreen="singlescreen" consult_type={consult_type} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} updateVitalsDataOnGynae = {this.updateVitalsData} />
                {/* : null} */}
              </>
              :
              null
          }
        </>;
        parent_variable.patient_hx = <>
          {
            ss_configuration.some(o => (o?.id === 2 && o.sub_items.some(p => ((p?.id === 3 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 4 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 5 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 6 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 7 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 8 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card pathistory-card mb-0">
                  <div className="card-header pathistory-card-head p-0" id="headingTwo">
                    <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapsePatientHistory" aria-expanded="false" aria-controls="collapsePatientHistory" onClick={(e) => this.handleTabClick('objective')}>
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>PATIENT HISTORY</h6>
                        </div>
                        {field_status_patient_history?.map((item, i) =>
                          <>
                            <div className='col-2'>
                              {item.status ?
                                <span className='checkmark'></span>
                                : ""}
                              <span className='pl-2 pathis-headings'>{item.name}</span>
                            </div>
                          </>
                        )}
                      </div>
                    </button>
                  </div>
                  <div id="collapsePatientHistory" className="collapse" aria-labelledby="headingTwo" >
                    {this.state.isObjectiveShow ?
                      <ObjectiveComponentSS sourcescreen="singlescreen" consult_type={consult_type} patient={patient} dataLoading={this.state.dataLoading} initialSetup={this.initialSetup} getPatientData={this.getPatientData} /> :
                      null}
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.subjective = <>
          {
            ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 11 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 12 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 13 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 14 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 15 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 16 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 17 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className='row py-2'>
                <div className='col-xl-12 col-lg-12'>
                  <div className="accordion soap_accordion" id="accordionExample">
                    <div className="card card-bg-lightblue sub-card mb-0 ">
                      <div className="card-body p-0">
                        <div className='container-fluid '>
                          {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                            <>
                              {
                                ss_configuration.some(o => (o?.id === 3 && o.sub_items.some(p => ((p?.id === 9 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 10 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
                                  ?
                                  <ChiefComplaintsHPI ref={this.chiefComponentRef} sourcescreen="singlescreen" consult_type={consult_type} history={this.props.history} isPracticing={this.state.isPracticing} hospital_id={this.state.hospital_id} patient={patient} allowEdit={allowEdit} subjectiveChange={this.subjectiveChange} loadCopyPrescriptionAddedData={this.loadCopyPrescriptionAddedData} default_text={this.state.default_text} micSpeech={this.state.speech} onMicClick={(getData) => this.initSpeech(getData)}/>
                                  :
                                  null
                              }
                              {
                                child_subjective && child_subjective.length > 0
                                  ?
                                  (
                                    child_subjective.map((ssc, i) => {
                                      return (child_variable[ssc.name])
                                    })
                                  )
                                  :
                                  null
                              }
                            </>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              :
              null
          }
        </>;

        parent_variable.obs_gynae = <>
          {
            ss_configuration.some(o => (o?.id === 16 && o.sub_items.some(p => ((p?.id === 21 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 22 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 23 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 24 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 25 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 26 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 27 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 28 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 29 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 30 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 31 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 32 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 33 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))) && ((isObsGynaePatient || isObsGynaePatient == 'true')) && ((call_patient_detail.age > 10))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card  gynae-card mb-0" id="med_scroll">
                  <div className="card-header gyn-card-head p-0" id="gynAccordion">
                    {/* <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseGynae" aria-expanded="false" aria-controls="collapseGynae" > */}
                    <button ref={this.obsGynaeCollapseRef} className={lengthUsingKeys.length > 0 && hasValues ? "btn btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" : "btn btn-block text-left collapsed arrow float-right mb-0 custom-card-pink instr_padd"} type="button" data-toggle="collapse" data-target="#collapseGynae" aria-expanded="true" aria-controls="collapseGynae">
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>GYNAE & OBS</h6>
                        </div>
                        <div className='col-10 text-right pr-5'>
                          {/* <a href="#" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}>
                            <Checkbox
                             name="isConfidentialMen"
                             checked={this.state.isConfidentialMen}
                             onClick={(e) => this.handConfidentialMen(e)}
                            >
                              <span className='text-secondary'>Confidential</span>
                            </Checkbox>
                          </a> */}
                           <button className="btn btn-outline-primary text-right text-uppercase  small  px-2" onClick={this.goToANCCard}> ANTENATAL CARD </button>

                        </div>
                      </div>
                    </button>
                  </div>
                  <div id="collapseGynae" className="collapse" aria-labelledby="gynAccordion" >
                    <GynaeSingleScreen setCycleLength={this.setCycleLength} obsGyaneData={details.obsGyaneData} prevPregnencyCopied={this.state?.details?.qms_details?.prev_pregnancy_copied} obsGynaePrevData={details.obsGynaePrevData} fetalParamsPrevData={this.state.fetalParamsPrevData} physicalExamPrevData={this.state.physicalExamPrevData} setMultipleFetalParams={this.setMultipleFetalParams} fetalParamsData={this.state.multipleFetalParamsData} physicalExamData={this.state.physicalExamData} vitalsData={this.state.vitalsDataFromVitals} setPhysicalExamData = {this.setPhysicalExamData}/> 
                  </div>
                </div>
              </div>
:
              null
          }
        </>
        parent_variable.diagnosis = <>
          {
            ss_configuration.some(o => (o?.id === 4 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion my-2" id="accordionExample">
                <>
                  {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                    <AssessmentComponentSS
                      ref={this.assessmentComponentRef}
                      history={this.props.history}
                      hospital_id={this.state.hospital_id}
                      patient={patient}
                      allowEdit={allowEdit}
                      cpDiagnosisType={this.state.cpDiagnosisType}
                      allCP={this.state.allCP}
                      otherCP={this.state.otherCP}
                      suggestedCP={this.state.suggestedCP}
                      suggestedDiagnosis={this.state.suggestedDiagnosis}
                      loadCareProtocolAddedData={this.loadCareProtocolAddedData}
                      assessmentLength={this.calculateAssessmentLength.bind(this)}
                    />
                    : null}
                </>
              </div>
              :
              null
          }
        </>;
        parent_variable.medicine = <>
          {
            ss_configuration.some(o => (o?.id === 5 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card medicines-card mb-0" id="med_scroll">
                  <div className="card-header med-card-head p-0" id="medAccordion">
                    <button ref={this.medicineCollapseRef} className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseMedicines" aria-expanded="false" aria-controls="collapseMedicines" onClick={(e) => this.handleTabClick('plan_medicine')}>
                      {/* <h6>MEDICINES</h6> */}
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>MEDICINES</h6>
                        </div>
                        <div className='col-10 text-right pr-5'>
                          {/* <a 
                          href="#" 
                          className='text-secondary pr-3' 
                          onClick={(e) => {
                            this.state.allowEdit && this.medicineComponentRef.current.handleCurrentMedicines();
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        > */}

                          <span href="#" class="pr-3 align-self-center">
                            <CurrentMedsPopup patient={this.state.patient} />
                          </span>
                          <a
                            class="btn btn-outline-secondary small mr-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openMedicineComponent();
                              this.state.allowEdit && this.medicineComponentRef.current.handleOrderSetPopup(e, 'show');
                              e.stopPropagation();
                            }}
                          >
                            ORDER SET
                          </a>
                          <a
                            class="btn btn-outline-primary small ml-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openMedicineComponent();
                              this.state.allowEdit && this.medicineComponentRef.current.handleModalPopup(e, !this.state.isPracticing ? "isPracticingModal" : "showMedicineModal");
                              e.stopPropagation();
                            }}
                          >
                            + MEDICINES
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div id="collapseMedicines" className="collapse" aria-labelledby="medAccordion" >
                    {this.state.isMedShow ?
                      <PlanMedicineComponentSS getDrugsList={this.addedDrugs} ref={this.medicineComponentRef} patient={this.state.patient} updateInstructionFromParent={this.updateInstructionFromParent} viewMedicineData={this.viewMedicineData} viewlengthMedicineData={this.viewlengthMedicineData.bind(this)} printCompleteButtonDiabled={this.printCompleteButtonDiabled} obsGyaneData = {this.state.gyaneData}/> :
                      null}
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.test = <>
          {
            ss_configuration.some(o => (o?.id === 6 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card tests-card mb-0" id="med_scroll">
                  <div className="card-header tests-card-head p-0" id="testAccordion">
                    <button ref={this.testCollapseRef} className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseTests" aria-expanded="false" aria-controls="collapseTests" onClick={(e) => this.handleTabClick('plan_test')}>
                      {/* <h6>TESTS</h6> */}
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6>TESTS</h6>
                        </div>
                        <div className='col-10 text-right pr-5'>

                          <a
                            class="btn btn-outline-secondary small mr-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openTestComponent();
                              this.state.allowEdit && this.testComponentRef.current.handleOrderSetPopup('show');
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            ORDER SET
                          </a>
                          <a
                            class="btn btn-outline-primary small ml-2 px-2"
                            href="#"
                            onClick={(e) => {
                              this.openTestComponent();
                              this.state.allowEdit && this.testComponentRef.current.handleModalPopup(e, !this.state.isPracticing ? "isPracticingModal" : "showTestModal");
                              e.stopPropagation();
                            }}
                          >
                            + TESTS/PROCEDURES
                          </a>
                          {((sendTestOrder == true || sendTestOrder == 'true')  && this.state.testLength > 0 && this.state.unsentTestLength > 0) ?
                            <button
                              class="btn btn-outline-primary small ml-2 px-2"
                              disabled={this.state.sendOrderDisabled}
                              onClick={async (e) => { 
                                this.setState({sendOrderDisabled: true})
                                e.preventDefault();
                                e.stopPropagation();
                                await this.sendTestOrder();
                                this.state.allowEdit && this.testComponentRef.current.handleInitialData();
                                this.setState({sendOrderDisabled: false})
                              }}
                            >
                              SEND ORDERS{this.state.sendOrderDisabled == true ? LOADER : null}
                            </button>
                          : null }
                        </div>
                      </div>
                    </button>
                  </div>
                  <div id="collapseTests" className="collapse" aria-labelledby="testAccordion" >
                    {this.state.isTestShow ?
                      <PlanTestComponentSS ref={this.testComponentRef} patient={patient} updateInstructionFromParent={this.updateInstructionFromParent} viewTestData={this.viewTestData} viewlengthTestData={this.viewlengthTestData.bind(this)} viewlengthUnsentTestData={this.viewlengthUnsentTestData.bind(this)} /> :
                      null}
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.instruction = <>
          {
            ss_configuration.some(o => (o?.id === 7 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className="accordion soap_accordion py-2" id="accordionExample">
                <div className="card instructions-card mb-0" id="med_scroll">
                  <div className="card-header instructions-card-head p-0" id="instr_accordion">
                    <button ref={this.instructionCollapseRef} className={this.state.instructionList?.length > 0 ? "btn btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" : "btn btn-block text-left collapsed float-right mb-0 custom-card-pink instr_padd"} type="button" data-toggle="collapse" data-target="#collapseInstructions" aria-expanded="false" aria-controls="collapseInstructions">
                      <div className='row'>
                        <div className='col-2 d-flex align-self-center'>
                          <h6 className='mb-0'>INSTRUCTIONS</h6>
                        </div>
                        <div className={this.state.instructionList?.length > 0 ? 'col-10 text-right pr-5' : 'col-10 text-right pr-5 '}>
                          <a
                            class="btn btn-outline-primary small ml-2 px-2"
                            href="#"
                            onClick={e => this.handleModalPopup(e, 'showInstructionModal')}
                          >
                            + INSTRUCTIONS
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>
                  {
                    this.state.instructionList?.length > 0
                      ?
                      <div id="collapseInstructions" className="collapse" aria-labelledby="instr_accordion" >
                        <div className="card-body instructions_body p-0">
                          <div className='container-fluid instruc_container pt-1'>
                            <div class="all_instructions">
                              <Tooltip placement="topLeft" title="Delete All">
                                <i className='icon_delete icon_delete_all float-right cursor-pointer' onClick={() => this.deleteAllInstruction()} ></i>
                              </Tooltip>
                              <div class="row instructions_rows mt-0">
                                {
                                  this.state.instructionList.map((inst, i) => {
                                    return (
                                      <div class=" instructions_list2 col-md-6 d-flex align-items-top pl-2">
                                        <div class="dot-bullet"></div>
                                        <div class="instructions_text p-0 ">
                                          <p class="p-0"><pre style={{fontSize : "inherit"}}>{inst.instruction}</pre></p>
                                        </div>
                                        <div class="col text-center ">
                                          <a
                                            class="btn nostyle-link p-0 ml-1 mr-2"
                                            href="javascript:void(0)"
                                            onClick={() => this.handleEditInstruction(inst)}
                                          >
                                            <i class="icon-edit"></i>
                                            
                                          </a>
                                          <a class="btn nostyle-link p-0 ml-1 mr-2 "
                                            onClick={(e) => this.onInstructionSelect(inst.id, inst.instruction, inst.instruction)}
                                            href="javascript:void(0)"><i class="icon_delete "></i></a>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }
        </>;

        parent_variable.supporting_material = <>
          {
            ss_configuration.some(o => (o?.id === 8 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <>
                {this.state.isSupportingMaterialShow ?
                  <SupportingMaterialSS
                    formType='all'
                    referenceId={this.state?.reference_id}
                    referenceType={REFERENCE_TYPE}
                    hospitalId={this.state.hospital_id}
                    setMaterialData={(data) => this.getMaterialData(data)}
                    allSupportingMaterial={this.state.materialList}
                    updateSupportingMaterial={this.updateSupportingMaterial}
                  />
                  : null}
              </>
              :
              null
          }
        </>
        parent_variable.referral = <>
          {
            ss_configuration.some(o => (o?.id === 9 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <ReferralComponentSS patient={patient} allowEdit={this.state.allowEdit} />
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_1 = <>
          {
            ss_configuration.some(o => (o?.id === 11 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom1 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom1.name }) }}
                      className={(this.state.speech.identifier === parent_custom1.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom1} name={parent_custom1.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom1} name={parent_custom1.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>  
                    <textarea
                      placeholder={parent_custom1?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom1.name}
                      name={parent_custom1.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom1}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom1?.name}>{parent_custom1?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_2 = <>
          {
            ss_configuration.some(o => (o?.id === 12 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom2 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom2.name }) }}
                      className={(this.state.speech.identifier === parent_custom2?.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom2} name={parent_custom2.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom2} name={parent_custom2.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>  
                    <textarea
                      placeholder={parent_custom2?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom2?.name}
                      name={parent_custom2?.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom2}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom2?.name}>{parent_custom2?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_3 = <>
          {
            ss_configuration.some(o => (o?.id === 13 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom3 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom3.name }) }}
                      className={(this.state.speech.identifier === parent_custom3?.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom3} name={parent_custom3.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom3} name={parent_custom3.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>    
                    <textarea
                      placeholder={parent_custom3?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom3?.name}
                      name={parent_custom3?.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom3}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom3?.name}>{parent_custom3?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_4 = <>
          {
            ss_configuration.some(o => (o?.id === 14 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom4 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom4.name }) }}
                      className={(this.state.speech.identifier === parent_custom4.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom4} name={parent_custom4.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom4} name={parent_custom4.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>  
                    <textarea
                      placeholder={parent_custom4?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom4?.name}
                      name={parent_custom4?.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom4}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom4?.name}>{parent_custom4?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.custom_field_parent_5 = <>
          {
            ss_configuration.some(o => (o?.id === 15 && o?.configuration?.[consult_type + '_screen']?.selected == 'Y'))
              ?
              <div className='row'>
                <div className='col-xl-12 col-lg-12 mt-2'>
                  <div className={this.state?.parent_customdata?.parent_custom5 ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap`}>
                    {this.state.allowEdit ? <Link
                      onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: parent_custom5.name }) }}
                      className={(this.state.speech.identifier === parent_custom5.name && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link> : null}
                    <img value={this.state?.parent_customdata?.parent_custom5} name={parent_custom5.name} onClick={(e) => this.saveNotesForParentModel(e, 'view_model')} src={TextSaveImg} style={{ position: 'absolute', right: '135px', top: '7px', zIndex: '9', width: '27px', height: 'auto', cursor: 'pointer' }} />
                    <button value={this.state?.parent_customdata?.parent_custom5} name={parent_custom5.name} style={{ position: 'absolute', right: '40px', top: '7px', zIndex: '9' }} className="btn btn-outline-secondary py-1 px-2" onClick={(e) => this.saveNotesForParentModel(e, 'save_model')}>Save as <span style={{ border: "solid 1px #5c5c5c", padding: '0.15rem 0.25rem', borderRadius: '2px' }}>T</span></button>    
                    <textarea
                      placeholder={parent_custom5?.custom?.value}
                      className={`form-control subjective-field`}
                      style={{paddingRight:'170px'}}
                      id={parent_custom5.name}
                      name={parent_custom5.name}
                      disabled={!this.state.allowEdit}
                      value={this.state?.parent_customdata?.parent_custom5}
                      onChange={this.saveParentCustomSubjective}
                      onFocus={this.handleOnFocus}
                      onBlur={this.saveParentCustomSubjective}
                      rows="1"
                      onInput={(e) => this.autoHeight(e)}>
                    </textarea>
                    <label htmlFor={parent_custom5?.name}>{parent_custom5?.custom?.value}</label>
                  </div>
                </div>
              </div>
              :
              null
          }
        </>;
        parent_variable.other = <>
          {
            ss_configuration.some(o => (o?.id === 10 && o.sub_items.some(p => ((p?.id === 18 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 19 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y') || (p?.id === 20 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))
              ?
              <div className="row emrfrm py-2" id="followup">
                <div className="col-12">
                  <div className="card followup_card rounded">
                    <div className="card-body px-3 py-1">
                      {
                        child_others && child_others.length > 0
                          ?
                          (
                            child_others.map((ssc, i) => {
                              return (child_variable[ssc.name])
                            })
                          )
                          :
                          null
                      }
                    </div>
                  </div>
                </div>
              </div>
              :
              null

          }

        </>;
      }

    }
  


    return (
      <>
        {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}
        {!dataLoading ? (
          <div className="content-wrapper">
            <div className="container-fluid emrfrm ">
              <div className="row single_page">
                <div className="col-12">
                  {referralDetailsArr?.length > 0 || (
                    patient_note_doctor?.length > 0
                    || patient_docments?.length > 0
                  ) ? (
                    <div className="card card-bg-lightblue mb-2 ">
                      <div className="card-body p-0">
                        <div className='container-fluid patnotes-container'>
                          <div className='row'>
                            {patient_note_doctor?.length > 0 || patient_docments?.length > 0 || referralDetailsArr?.length > 0 ? (
                              <>
                                <div className='col-4 '>
                                  {patient_note_doctor?.length > 0 ? (
                                    <h6 className='mb-0'>PATIENT NOTES FOR DOCTOR</h6>
                                  ) : null}
                                </div>
                                <div className='col-8'>
                                  <div className='row'>
                                    {referralDetailsArr?.length > 0 ? (
                                      <div className={(patient_note_doctor?.length > 0) ? "col-xl-7 col-lg-6 col-5 text-right" : "col-xl-7 col-lg-6 col-5 text-right"}>
                                        <Tooltip placement="bottomLeft" title={refferaltext} overlayClassName='soap_tooltip' overlayStyle={{ maxWidth: '500px' }}>
                                          <span class="referral_counter">!</span>
                                          <span className='text-primary upload_pat_docs' style={{ cursor: 'pointer' }}><u>REFERRAL</u> <a onClick={this.handleReferralDetails} >{referralDetailsArr?.length > 1 ? (<span class="upload_counter">{referralDetailsArr?.length}</span>) : null}</a></span>
                                        </Tooltip>
                                      </div>
                                    ) : null}
                                    {patient_docments?.length > 0 ? (
                                      <div className='col text-right'>
                                        {
                                          patient_docments?.length > 0 ? (
                                            <>
                                              <a href="#" onClick={e => this.handleModalPopup(e, 'viewDocumentModal', 0, 'img')}><span className='text-primary upload_pat_docs'><u>UPLOADED DOCUMENTS</u></span></a>
                                              <span class="upload_counter">{patient_docments?.length}</span>
                                            </>
                                          ) : null
                                        }
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </>) : null}
                          </div>
                          <div className='row mt-1'>
                            {patient_note_doctor?.length > 0 ? (
                              <>
                                <div className='col-12' style={{ textAlign: 'justify' }}>
                                  <span>{patient_note_doctor}
                                  </span>
                                </div>
                              </>) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {
                    ss_configuration && ss_configuration.length > 0
                      ?
                      (
                        ss_configuration.map((ss, i) => {
                          return (parent_variable[ss.name])
                        })
                      )
                      :
                      null
                  }
                  {
                    this.state.is_recall_doctor == 1 ?
                      <div className='row mt-1'>
                        <div className='col-xl-12 col-lg-12'>
                          <div className={this.state.reviewTxt != '' && this.state.reviewTxt != null ? `form-group micwrap ant-mic-wrap hasdata` : `form-group micwrap ant-mic-wrap `}>
                            <Link
                              onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'review_notes' }) }}
                              className={(this.state.speech.identifier === 'review_notes' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                            <textarea class="form-control subjective-field show-placeholder"
                              id="review_notes"
                              name="review_remark"
                              rows="1"
                              onFocus={this.handleOnFocus}
                              onInput={(e) => this.autoHeight(e)}
                              onChange={this.reviewNote}
                              defaultValue={this.state.reviewTxt}
                              value={this.state.reviewTxt}
                            >
                            </textarea>
                            <label for="review_notes">Review Remarks</label>
                          </div>
                        </div>
                      </div> : null
                  }

                  <div className="fixed-cta soap-fixcta">
                    {this.state.cimsallow && addedDrugsList.length > 0 ? <button type="submit" className="btn btn-outline-secondary px-4 text-uppercase template-save mr-3" disabled={this.state.disableConfirmBtn} onClick={() => this.showModalInteraction()}><i className="icon-medinfo drug_info mr-2" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}></i>VIEW ALL INTERACTIONS </button> : null}
                    {this.showCP() ?
                      <button className="btn btn-outline-secondary text-uppercase px-5 mr-3 ml-3" disabled={this.state.disableConfirmButton || medicineDataEmpty} onClick={this.handleViewPresc}>SAVE AS CARE PROTOCOL</button>

                      : null}

                    <button className="btn btn-outline-secondary text-uppercase px-5 ml-3" disabled={this.state.disableCompleteButton || this.state.isPrintDraftLoader || this.state.isPrintLoader || !this.state.allowEdit} onClick={this.handleCompleteAppointmentDraft}>PRINT AS DRAFT{this.state.isPrintDraftLoader ? LOADER : null}</button>
                    
                    {
                      (LS_SERVICE.has('search_patient_page') && LS_SERVICE.get('search_patient_page') == false) ?
                        ((LS_SERVICE.get('call_patient_detail').visit_status != 1) || (LS_SERVICE.get('call_patient_detail').visit_status == 1 && (this.state.allowEdit || this.state.allowAddendum)))
                          ?
                          (
                            <Tooltip placement="topLeft" title={this.state.medicineDataEmpty == true ? "Medicine data  is mandatory" : this.isPrintButtonDisabled() ? "Chief complaint or admission advised is not entered" : null}> <button className="btn btn-primary text-uppercase px-5 ml-3" disabled={this.isPrintButtonDisabled()} onClick={this.handleCompleteAppointment}>PRINT & COMPLETE RX{this.state.isPrintLoader ? LOADER : null}</button></Tooltip>
                          )
                          : 
                          (
                            <button className="btn btn-primary text-uppercase px-5 ml-3" disabled={this.state.disableCompleteButton || this.state.isPrintLoader || !this.state.allowEdit} onClick={this.handleCompleteAppointment}>PRINT & COMPLETE RX{this.state.isPrintLoader ? LOADER : null}</button>
                          )
                          :
                        null
                    }                    

                    {isReferral === true ? (
                      <Tooltip placement="topLeft" title={this.state.referred_by ? `Referred By ${this.state.referred_by} - Change Referral Source` : referralByFromHIS ? `Referred By ${referralByFromHIS} - Change Referral Source` : "Add Referral Source"}>
                        <a 
                          disabled={!this.state.allowEdit} 
                          onClick={
                            this.state.allowEdit ?
                            e => this.handleModalPopup(e, 'addReferralSourceModal')
                            : {}
                          } 
                          className='text-secondary mr-3' 
                          style={{ float: 'right' }}>
                          <u>
                            Referral Source
                          </u>
                        </a>
                      </Tooltip>
                    ) : null}

                  </div>
                  {this.state.notesModelActive ?
                    <NotesSaveModel content={this.state.content} resetNotesModel={this.resetNotesModel} resetModel={this.resetModel} saveTextBoxData={this.saveTextBoxData} input_type={this.state.input_type} model_type={this.state.model_type} /> : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="col d-flex justify-content-center align-self-center mt-2">
            {LOADER_RED}
          </div>
        )}


        <Modal
          title={false}
          closable={true}
          visible={this.state.addReferralSourceModal}
          footer={false}
          onCancel={e => this.handleModalPopup(e, 'addReferralSourceModal')}
        >
          <div className="row">
            <div className="col-12 mb-3 text-left"><h6>Add Referral Source</h6></div>

            <div className="col-12">
              <div className="form-group">
                <label htmlFor="referred_by">Referred By</label>
                <Input
                  type="text"
                  id="referred_by"
                  className="form-control"
                  placeholder="Referred By"
                  onChange={(e) => this.onChangeReferredBy(e)}
                  defaultValue={this.state.referralName ? this.state.referralName : referralByFromHIS}
                  name="referred_by"
                  maxLength={80}
                  autoComplete='off'
                />
              </div>
            </div>

            <div className="col-12 text-center mt-3">
              <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={e => this.handleModalPopup(e, 'addReferralSourceModal')}>CANCEL</button>
              <Button
                key="submit"
                className="btn btn-primary px-4"
                type="primary"
                onClick={() => this.referredByOnSubmit()}
                disabled={this.state.isButtonDisabled}
              >
                SUBMIT
                {/* {this.state.referralName != null ? "UPDATE" : "SAVE"} */}
              </Button>
            </div>
          </div>
        </Modal>


        {/** Document Viewer */}
        <Modal
          title={patient_docments?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className='row mb-3'>
            <div className='col-12 text-right emrfrm'>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="select_all"
                  name="select_all"
                  value={this.state.downloadAllfiles}
                  checked={this.state.downloadAllfiles}
                  onChange={(e) => this.selectAllFiles(e)}
                />
                <label className="custom-control-label" htmlFor="select_all">Select All</label>
              </div>
              <button href={this.state.documentURL} title={this.state.documentURL} disabled={this.state.download_file_loading ? true : false} className={this.state.download_file_loading ? "btn btn-primary px-2" : "btn btn-outline-primary px-2"} onClick={(e) => this.downloadFiles(e)} download>DOWNLOAD {this.state.download_file_loading ? LOADER : null}</button>
            </div>
          </div>
          <div className="pdf_slider">
            {
              patient_docments?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef} beforeChange={this.beforeChange} >
                  {
                    patient_docments?.map((file, index) => (
                      file.type === 'img' ?
                        <figure key={index}><Image className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                        :
                        <iframe key={index} className="mr-3" src={file.src + "#toolbar=0&scrollbar=0&pagemode=none"} ></iframe>

                    ))
                  }
                </Carousel>
              ) : null
            }
          </div>
        </Modal>

        {/* View Admision Request Modals */}
        <Modal
          destroyOnClose={true}
          title={"Active Admission Request"}
          visible={this.state.viewAdmissionGrid}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({ viewAdmissionGrid: false })}
          className="emrfrm surgery_modal"
        > 
          <div className="row my-2">
            <div className="col-12 table-responsive">
              <table className="table table-bordered rounded-0 surgerytable">
                <thead>
                  <tr>
                    <th>Request Number</th>
                    <th>Requested By</th>
                    <th>Advised Date</th>
                    <th>Case Type</th>
                    <th>Practitioner Name</th>
                    <th>Speciality</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {this.admissionListLoad()}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

        <Modal
          title={false}
          closable={true}
          visible={this.state.isReferralModal}
          footer={false}
          onCancel={e => this.handleModalPopup(e, 'isReferralModal')}
        >
          <div className="row">
            <div className="col-12 mb-3 text-left"><h6>REFERRAL DETAILS</h6></div>
            {referralDetailsArr?.map((element, index) => (
              (index != 0) ?
                <div className="col-12 mb-3" id="patient_notes_docs">
                  <div className="card bg-grey rounded-0">
                    <div className="card-body">
                      <div className='row'>
                        <div className="col-12">
                          <div className="">
                            <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Referral {index + 1}</h6>
                            <p className='mb-1'>Referral Date & Time : <span className="text-dark">{element.referral_date_time}</span></p>
                            <p className='mb-1'>Referral By : <span className="text-dark">Dr {element.referral_by}</span></p>
                            <p className='mb-1'>Referral Note : <span className="text-dark">{element.referral_note}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            ))}
            <div className="col-12 text-center mt-3">
              <button type="button" className="btn btn-outline-secondary px-4 small  mr-2" onClick={e => this.handleModalPopup(e, 'isReferralModal')}>CANCEL</button>
            </div>
          </div>
        </Modal>

        {/** || isPracticing Modal */}
        <Modal
          title={false}
          closable={false}
          visible={this.state.isPracticingModal}
          footer={false}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
          </div>
        </Modal>

        {/* For Modal Instruction  */}
        {this.state.showInstructionModal && (
          <Modal
            title={`${this.state.actionText} Instructions`}
            visible={this.state.showInstructionModal}
            onCancel={() => this.setState({ showInstructionModal: false })}
            footer={false}
          >
            <InstructionsForm
              ref={this.instructionFormRef}
              actionText={this.state.actionText}
              updateData={this.updateInstructionsData}
              showdeletebutton={this.state.showdeletebutton}
              addedInstructionsData={this.state.instructionList}
              handleModalPopup={(e, popupName) =>
                this.handleModalPopup(e, popupName)
              }
              // Pass the instruction data to the InstructionsForm component
              instructionData={this.state.editData}
            />
          </Modal>
        )}
        <Modal
          visible={this.state.deleteInstructionModal}
          onCancel={this.handleTestModalPopup}
          style={{ top: 40 }}
          title="Are you sure you want to delete all instructions?"
          closable={false}
          footer={[
            <div className="text-center">
              <button
                className="btn btn-outline-secondary px-5 cancelOrderSet"
                onClick={this.handleTestModalPopup}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary px-5 deleteOrderSet ml-1"
                disabled={this.state.spin_loading}
                id='deleteButton'
                onClick={this.handleDeletePopSubmit}
              >
                DELETE{this.state.spin_loading ? LOADER : ""}
              </button>
            </div>,
          ]}
        ></Modal>

        {/* Admit Order Modal */}

        <Modal
          destroyOnClose={true}
          title={"Admit Orders (Internal Use Only)"}
          visible={this.state.admitOrderModal}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({ admitOrderModal: false })}
          className="emrfrm"
        >
          {this.state.admitOrderModal ? <AdmitOrders patient={patient} allowEdit={this.state.allowEdit} admissionAdvisedDisabled={this.state.admissionAdvisedDisabled} handleAdmitOrderModal={this.handleAdmitOrderModal}/> : null } 
        </Modal>

        {/* Surgery Request Modal */}

        <Modal
          destroyOnClose={true}
          maskClosable={false}
          title={"Surgery Request"}
          visible={this.state.surgeryRequestModal}
          footer={false}
          width={850}
          onCancel={(e) => this.setState({ surgeryRequestModal: false })}
          className="emrfrm surgery_modal"
        >
          {this.state.surgeryRequestModal ? <SurgeryRequestForm patient={patient} handleSurgeryCancel={this.handleSurgeryCancel} constants={this.state.constants} allowEdit={this.state.allowEdit} admissionAdvisedDisabled={this.state.admissionAdvisedDisabled} /> : null}
        </Modal>

        {/* Surgery Modal */}

        <Modal
          destroyOnClose={true}
          maskClosable={false}
          title={"Surgery"}
          visible={this.state.surgeryModal}
          footer={false}
          width={700}
          onCancel={(e) => this.setState({ surgeryModal: false })}
          className="emrfrm"
        >
          {this.state.surgeryModal ? <SurgeryForm patient={patient} handleSurgeryFormCancel={this.handleSurgeryFormCancel} allowEdit={this.state.allowEdit} admissionAdvisedDisabled={this.state.admissionAdvisedDisabled} /> : null}
        </Modal>

        <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
          title="All Drug Interaction"
          visible={this.state.isModalAllInteractionVisible}
          onOk={() => this.handleAllInteractionOk()}
          onCancel={() => this.handleAllInteractionCancel()}
          footer={false}
          style={{ width: 2000 }}
          zIndex={7776}
        >
          <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${ALL_CIMS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
          {/* <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleAllInteractionCancel(e)}>DON’T PROCEED</button>
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleAllInteractionOk(e)}>PROCEED TO ORDER</button>
          </div> */}
        </Modal>

       {/* For Save As Care Protocol */}

        <Modal
          id="alert_confirm_message"
          className="alert_confirm_message"
          visible={this.state.saveAsCareProtocolModal}
          // onOk={() => this.handleTestDelete()}
          bodyStyle={{ padding: "25px 10px" }}
          onCancel={() => this.setState({ saveAsCareProtocolModal: false })}
          closable={false}
          footer={false}
          width="400px"
        >
          <div className="container-fluid emrfrm">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    id="template_name"
                    className="form-control"
                    placeholder="Care Protocol Name"
                    onChange={this.onChangeTemplateName}
                    defaultValue={this.state.templateName}
                    name="template_name"
                    maxLength={80}
                  />
                  <label htmlFor="template_name">Care Protocol Name</label>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center mt-2">
                <Button key="back" className="btn btn-outline-secondary px-4 mr-2" onClick={() =>{ 
                  this.setState({
                    saveAsCareProtocolModal: false,
                    templateName: ""
                  });
                  }}>
                  CANCEL
                </Button>
                <Button
                  key="submit"
                  className="btn btn-primary px-4"
                  type="primary"
                  disabled={this.state.savedisabled}
                  onClick={() => this.templateSubmit()}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </Modal>  

        <Modal
          title="View Draft Prescription"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null, rxPdfPrint: false }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.showLoader ? (
                LOADER_RED
              ) : (
                this.renderPdfViewer()
              )}
            </div>
          </div>
        </Modal>

      </>
    )
  }
}

export default SoapSingleParent;